import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import { TaskGrid, TaskContainer, Entry, P, Button, Header, Actions, IconButton } from './TaskType.style';
import { LuFileEdit, LuTrash, LuPlus, LuCopyCheck } from 'react-icons/lu';
import { formatMessage } from '../../locale';
import { paths } from '../../config/paths';
import { Paper } from '../../ui-kit';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { HideOnMobile } from '../../components/BaseLayout/BaseLayout.styles';
import ButtonSecondary from '../../ui-kit/Button/ui/components/ButtonSecondary';
import { useGetAllTaskTypesQuery, useDeleteTaskTypeMutation } from '../../slices/taskTypeApiSlice';
import { TaskTypePermisson } from '../../enum/TaskPermisson/TaskTypePermisson';
import PaginableList from '../../ui-kit/PaginableList';
import CopyPopUp from './Components/CopyPopUp/CopyPopUp';

const TaskType: React.FC = () => {
  const navigate = useNavigate();
  const { intl } = useIntlContext();
  const { userInfo, userCompany, permissions } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => permissions && !!permissions[permission];

  const [currentPage, setCurrentPage] = useState(1);
  const [isCopyPopupOpen, setCopyPopupOpen] = useState(false);
  const [selectedTaskTypeId, setSelectedTaskTypeId] = useState<number | null>(null);
  const [deleteTaskType] = useDeleteTaskTypeMutation();

  const openModal = (id: number) => {
    setSelectedTaskTypeId(id);
    setCopyPopupOpen(true);
  };

  const closeCopyPopup = () => {
    setCopyPopupOpen(false);
    setSelectedTaskTypeId(null);
  };

  return (
    <PaginableList query={useGetAllTaskTypesQuery} currentPage={currentPage}>
      {(taskTypes, isLoading, error, refetch) => {
        if (isLoading) return <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>;
        if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingTaskTypes', defaultMessage: 'Error while loading task types' })}</p></div>;

        const allData = taskTypes || [];

        async function handleDelete(id: number) {
          try {
            await deleteTaskType({ id }).unwrap();
            refetch();
          } catch (error) {
            console.error('Error deleting task type:', error);
          }
        }

        return (
          <>
            <TaskGrid>
              {allData.map((item: any, index: number) => (
                <TaskContainer key={index}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
                    <h3 style={{ textAlign: 'left', overflowWrap: 'break-word', wordWrap: 'break-word', maxWidth: 'calc(100% - 35%)' }}>
                      {item.name}
                    </h3>
                    <Actions>
                      {canDo(TaskTypePermisson.taskTypeUpdate) && (
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/taskType/edit/${item.id}`);
                          }}
                        >
                          <LuFileEdit size={16} />
                        </IconButton>
                      )}
                      {canDo(TaskTypePermisson.taskTypeCopyToSubsidiary) && (
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            openModal(item.id);
                          }}
                        >
                          <LuCopyCheck size={16} />
                        </IconButton>
                      )}
                      {canDo(TaskTypePermisson.taskTypeDelete) && (
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            handleDelete(item.id);
                          }}
                        >
                          <LuTrash size={16} />
                        </IconButton>
                      )}
                    </Actions>
                  </div>

                  <div>
                    <Entry>{item.description}</Entry>
                    <div style={{ position: 'absolute', bottom: '20px' }}>
                      {item.created_at && (
                        <P style={{ fontSize: 12 }}>
                          {formatMessage({ id: 'taskType.createdAt', defaultMessage: 'Created at: ' })}
                          {new Date(item.created_at).toLocaleDateString()}
                        </P>
                      )}
                      {item.updated_at && (
                        <P>
                          {formatMessage({ id: 'taskType.updatedAt', defaultMessage: 'Updated at: ' })}
                          {new Date(item.updated_at).toLocaleDateString()}
                        </P>
                      )}
                    </div>
                  </div>
                  </TaskContainer>
              ))}
            </TaskGrid>
            {isCopyPopupOpen && selectedTaskTypeId !== null && (
              <CopyPopUp
                Id={selectedTaskTypeId}
                onClose={closeCopyPopup}
                onCopySuccess={refetch}
              />
            )}
            <Header>
              <ButtonSecondary style={{ marginTop: 20, alignSelf: 'flex-end' }} onClick={() => { navigate(paths.createTaskType) }}>
                <LuPlus size={20} />
                <HideOnMobile>
                  {formatMessage({ id: 'taskType.createNewTaskType', defaultMessage: 'Create new task type' })}
                </HideOnMobile>
              </ButtonSecondary>
            </Header>
          </>
        );
      }}
    </PaginableList>
  );
};

export default TaskType;