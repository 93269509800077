import PropTypes from 'prop-types';

function StatusIndicator({ color, label }: { color: string; label: string }) {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ 
                width: '8px', 
                height: '8px', 
                borderRadius: '50%', 
                backgroundColor: color, 
                marginRight: '5px' 
            }} />
            <p style={{ fontSize: 10 }}>{label}</p>
        </div>
    );
}

export default StatusIndicator;