import styled from 'styled-components';

export const Container = styled.div`
    margin: 20px;
`;

export const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 400px;
    gap: 20px;
`;

export const CalendarContainer = styled.div`
    max-width: 300px;
    height: 100%;
`;

export const ButtonTabs = styled.button<{ active: boolean, color: any }>`
  background-color: ${({ active, color }) => (active ? color : 'transparent')};
  color: ${({ active }) => (active ? 'white' : 'rgba(141, 141, 141, 1)')};
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  border-radius: 7px;
  font-weight: 400;
  height: 36px;
`;

export const ButtonFilter = styled.button<{ color: any, active?: boolean }>`
  display: flex;
  background-color: ${({ active, color }) => (active ? color : 'rgba(0, 0, 0, 0.09)')};
  color: ${({ active }) => (active ? 'white' : 'black')};
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  border-radius: 7px;
  height: 38px;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
`;
