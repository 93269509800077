import React from 'react';
import Select from 'react-select';
import { StylesConfig } from 'react-select';
import { useSelector } from 'react-redux';

interface CustomSelectProps {
  label: any;
  value: any;
  options: { value: any; label: string }[];
  onChange: (selectedOption: any) => void;
  placeholder: string;
  disabled?: boolean;
}

const CustomSelectMulti: React.FC<CustomSelectProps> = ({ label, value, options, onChange, placeholder, disabled }) => {
  const { userCompany } = useSelector((state: any) => state.auth)
  const customStyles: StylesConfig = {
    control: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      fontWeight: 'normal',
      fontSize: 14,
      borderRadius: 8,
      width: 300,
      borderColor: state.isFocused ? userCompany?.button_color : provided.borderColor,
      boxShadow: state.isFocused ? `0 0 0 1px ${userCompany?.button_color}` : provided.boxShadow,
      
      '&:hover': {
        borderColor: userCompany?.button_color,
      },
    }),
    option: (provided) => ({
      ...provided,
      textAlign: 'left',
      fontWeight: 'normal',
      fontSize: 12,
    }),
    menu: (provided) => ({
      ...provided,
      width: 300,
      overflowY: 'auto',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 135,
      width: 300,
      "::-webkit-scrollbar": {
        width: "10px",
        height: "2px",
      },
      "::-webkit-scrollbar-track": {
        background: "lightGrey",
        borderRadius: "8px"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "8px"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
        borderRadius: "8px"
      }
    }),
  };

  return (
    <div>
      <Select
        inputId="custom-select"
        value={value}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        isSearchable
        styles={customStyles}
        aria-label={label}
        isMulti={true}
        isDisabled={disabled}
        menuPosition='fixed'
        menuPortalTarget={document.body}
        menuShouldScrollIntoView={false}
        
      />
    </div>
  );
};

export default CustomSelectMulti;