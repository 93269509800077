// src/features/Profile/components/ProfileTabs.styles.tsx

import styled from 'styled-components';

export const TabsContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
`;

export const TabButton = styled.button<{ active?: boolean }>`
  display: flex;
  background-color: ${({ active, color }) => (active ? color : 'transparent')};
  color: ${({ active }) => (active ? 'white' : 'black')};
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 7px;
  height: 38px;
  align-items: center;
  justify-content: space-between;
  padding: 7px 25px;
`;

export const TabContent = styled.div`
    margin-top: 20px;
`;
