import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { formatMessage } from '../../locale';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import Tooltip from '../ToolTip/TooltipContainerTask';
import { TaskStatus } from '../../enum/Task/TaskStatus';
import { LuFileEdit, LuTrash } from 'react-icons/lu';
import { TaskPermisson } from '../../enum/Task/TaskPermisson';
import { Navigate } from 'react-router-dom';
import  StatusIndicator  from './StatusIndicator/StatusIndicator';

interface CardProps {
  id: number;
  title?: string;
  assignee?: any;
  creator?: any;
  taskType?: any;
  dueDate?: string;
  completedDate?: string;
  status?: any;
  createdAt?: string;
  updatedAt?: string;
  viewLink: string;
  font_color?: string;
  background_color?: string;
  is_overdue?: boolean;
  is_archived?: boolean;
  handleUpdate: (id: number) => void;
  handleDelete: (id: number) => void;
  canEdit?: boolean;
}

const CardContainer = styled.div`
  overflow: hidden;
  display: flex;
  corsor: pointer;
  flex-direction: column;
  width: 100%; /* Ensures the card takes up full width of its container */
  max-width: 428px; 
  min-width: 428px;
  padding: 30px;
  position: relative;
  transition: transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  &:hover {
    transform: scale(1.05);
  }
`;


const TitleContainer = styled.div`
  height: 50px;
`;

const Title = styled.p`
  font-size: 28px;
  font-weight: 'normal';
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.p`
  margin: 10;
  font-size: 14px;
   color: grey;
  `;

  export const Actions = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
`;

export const Actions2 = styled.div`
position: absolute;
top: 15px;
left: 30px;
display: flex;
gap: 10px;
`;

export const IconButton = styled.button`
  background: #efefef;
  border-radius: 3px;
  padding: 3px;
  padding-bottom: 1px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #000;
`;


const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  cursor: normal;
  gap: 20px;
  justify-content: start;
  width: 100%;
  
  @media (max-width: 400px) {
    justify-content: center;
  }
`;

export const Button = styled.button<{ active: boolean, color: any }>`
  background-color: ${({ active, color }) => (active ? color : 'transparent')};
  color: ${({ active }) => (active ? 'white' : 'rgba(141, 141, 141, 1)')};
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  border-radius: 7px;
  font-weight: 400;

`;

export const Card: React.FC<CardProps> = ({
                                            id,
                                            title,
                                            viewLink,
                                            assignee,
                                            taskType,
                                            dueDate,
                                            completedDate,
                                            status,
                                            createdAt,
                                            updatedAt,
                                            creator,
                                            font_color,
                                            background_color,
                                            is_archived,
                                            is_overdue,
                                            handleUpdate,
                                            handleDelete,      
                                            }) => {
  const navigate = useNavigate();
  const { intl } = useIntlContext();
  const { userCompany, permissions } = useSelector((state: any) => state.auth)
  const canDo = (permission: string) => permissions && !!permissions[permission];
  const user = userCompany?.button_color

  const [backgroundColor, setBackgroundColor] = useState<string>('');
  const [fontColor, setFontColor] = useState<string>('');
  
  useEffect(() => {
    if (status) {
        switch (status) {
            case TaskStatus.COMPLETED:
            case TaskStatus.COMPLETED_WITH_EVALUATION:
            case TaskStatus.COMPLETED_WITHOUT_EVALUATION:
                setBackgroundColor('rgba(26, 229, 190, 0.15)');
                setFontColor('rgba(0, 147, 119, 1)');
                break;
            case TaskStatus.CANCELLED:
            case TaskStatus.NOT_COMPLETED:
            case TaskStatus.OVERDUE:
                setFontColor('rgba(127, 95, 184, 1)');
                setBackgroundColor('rgba(219, 208, 245, 0.3)');
                break;
            case TaskStatus.ONGOING:
            case TaskStatus.ONGOING_EVALUATION:
                case TaskStatus.ARCHIVED: 
                setFontColor('rgba(146, 105, 0, 1)');
                setBackgroundColor('rgba(255, 231, 170, 0.4)');
                break;
            case TaskStatus.EVALUATION_POSSIBLE:
            case TaskStatus.PLANNED:
                setBackgroundColor('#ced0cb');
                setFontColor('#6f7669');
                break;
            default:
                break;
        }
    }
}, [status]);

  
  return (
    <CardContainer>
       <Link to={viewLink} style={{ textDecoration: 'none' }}> 
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
          <h3 style={{ textAlign: 'left', overflowWrap: 'break-word', wordWrap: 'break-word', maxWidth: 'calc(100% - 25%)', marginTop: 10 }}>
            {title}
          </h3>
          <Actions2>
            {is_archived && (
              <StatusIndicator color="#926900" label={formatMessage({ id: 'task.archived', defaultMessage: 'ARCHIVED' })} />
            )}
            {is_overdue && (
              <StatusIndicator color="red" label={formatMessage({ id: 'task.overdue', defaultMessage: 'OVERDUE' })} />
            )}
          </Actions2>

          <Actions>
            {canDo(TaskPermisson.UPDATE) && (
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdate(id);
                }}
              >
                <LuFileEdit size={16} />
              </IconButton>
            )}
            {canDo(TaskPermisson.DELETE) && (
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete(id);
                }}
              >
                <LuTrash size={16} />
              </IconButton>
            )}
          </Actions>
    </div>
        

    <div>     
        <p style={{color: 'rgba(124, 156, 191, 1)', fontWeight: 'bold',  display: 'inline-block', padding: '2px 5px', borderRadius: '5px', border: '2px solid rgba(124, 156, 191, 1)', marginTop: 10 }}>
        {formatMessage({ id: 'task.assigne', defaultMessage: 'Assigne: ' })}
           {assignee?.fullname}
          </p>
    </div>
        
      <div>
        <p style={{ fontSize: 16, color:'rgba(35, 35, 35, 1)', display: 'inline-block', padding: '2px 5px',  borderRadius: '5px', marginTop: 25 }}>
          {formatMessage({ id: 'task.taskType', defaultMessage: 'Task type' })}: {taskType?.name}
          </p>
      </div>
    
       {status &&
        <div style={{ display: 'flex', flexDirection: 'row' , marginTop: 10,  gap: 5, alignItems: 'center', padding: '2px 5px', }}>
          <p>Status:</p>
          <p style={{display: 'inline-block', borderRadius: '5px', padding: 5, color: font_color, backgroundColor: background_color, fontWeight: 'bold', textTransform: 'uppercase' }}>{status || 'N/A'} </p>
        </div>
      } 


        
        <div style={{ marginTop: 50 }}>
        {dueDate && (<Description key={dueDate}>{formatMessage({ id: 'task.dueDate', defaultMessage: 'Due Date' })} {dueDate}</Description>)}
        {completedDate && (<Description key={completedDate}>{formatMessage({ id: 'task.completedDate', defaultMessage: 'Completed date:' })} {completedDate}</Description>)}
        </div>
        
      <TitleContainer>
        <div
          style={{ 
            position: 'absolute', 
            padding: '10px 0', 
            bottom: 5, 
            color: user, 
            fontSize: 16, 
            fontWeight: 'bold', 
            transition: 'color, opacity', 
          }}
        > 
          <div style={{ justifyContent: 'space-between' }}>
         
          {updatedAt && (<Description key={updatedAt}>{formatMessage({ id: 'task.updateAt', defaultMessage: 'Updated at: ' })} {updatedAt}</Description>)}
          {createdAt && (<Description key={createdAt}>{formatMessage({ id: 'task.createAT', defaultMessage: 'Created at: ' })} {createdAt}</Description>)}
          </div>
          <div>
          
          </div>
        </div>

        <div
          style={{ 
            position: 'absolute', 
            padding: '10px 0', 
            bottom: 5, 
            right: 10,
            color: user, 
            fontSize: 16, 
            fontWeight: 'bold', 
          }}
        > 
  
          <Tooltip title={'Created by ' + creator?.fullname }>
            <Description key={creator?.id}>
                <div style={{ 
                width: '30px', 
                height: '30px', 
                borderRadius: '50%', 
                backgroundColor: user,
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                
                }}>
                <p style={{color:'white', fontWeight: 400}}>{creator?.fullname.split(' ').map((name: any) => name.charAt(0)).join('')}</p>
                </div>
            </Description>
            </Tooltip>

          </div>
                
              
        </TitleContainer>
      </Link> 
    </CardContainer>
  );
};

export const CardGrid: React.FC<{ cards: CardProps[] }> = ({ cards }) => {
  const { userCompany } = useSelector((state: any) => state.auth)

  return (
    <CardsWrapper>
      {cards.map((card) => (
        <Card key={card.id} {...card} />
      ))}
    </CardsWrapper>
  );
};
