import { paths } from '../../config/paths'
import { LuArrowLeft} from 'react-icons/lu'
import React, { useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useGetOneTaskQuery, useGetOneTaskRegularQuery, useUpdateTaskOneMutation } from '../../slices/taskApiSlice'
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import { useSelector } from 'react-redux'
import {
    Container, Header, Title, BackLink,
    Content, TaskContent, TaskType, InfoButton,
    InfoTitle, InfoSection, UserInfo, UserAvatar,
    UserInitials, UserName, Status, Dates, Footer,
    InfoContainer, ContainerTask, HeaderTask, Row,
    Label, FieldType, FieldValue, EditableField, IconButton, CustomSelectMultiContainer, HideOnMobile, 
    TabsContainer,
    TabButton,
    TabContent, UserNameInfo,  UserAvatarInfo,
    UserInitialsInfo, ButtonFilter, RowIsInput, ContainerTaskIsInput
} from './TaskPage.style'
import { TaskStatus } from '../../enum/Task/TaskStatus'
import { HtmlView } from '../../ui-kit/HtmlView/HtmlView'
import { CustomFieldPermisson } from '../../enum/TaskPermisson/CustomFieldPermisson'
import { LuPlusCircle } from 'react-icons/lu'
import { Form, Formik } from 'formik'
import CustomSelectMulti from '../../ui-kit/Dropdown/CustomDropdwonOverFlowParent/TaskDropdownMulti'
import CustomSelect from '../../ui-kit/Dropdown/CustomDropdwonOverFlowParent/TaskDropdown'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './DatePicker.module.css'
import {
    DROPDOW_OPTIONS,
    INPUT_FILED,
    DATE_OPTIONS,
    TASK_FORM_OPTIONS,
    EDITOR
} from '../../enum/Task/TaskFormPermisson'
import {
    useGetOptionValueCustomFieldsQuery, useUpdateTaskTypeStatusMutation, 
} from '../../slices/taskTypeApiSlice'
import ButtonSecondary from '../../ui-kit/Button/ui/components/ButtonSecondary'
import WangEditorTask from '../../ui-kit/Form/components/Editor/WangEditorTaskPage'
import DropdownStatus from '../../ui-kit/Dropdown/TaskOneDropdwon/DropdwonStatus'
import Paper from '../../ui-kit/Paper/Paper'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { v4 as uuidv4 } from 'uuid';
import Toast from '../../ui-kit/Toast/ToastTask';


export const TaskPage = () => {
    const { intl } = useIntlContext();
    const { id } = useParams();
    const { userCompany, userInfo } = useSelector((state: any) => state.auth);
    
    const { data: task, refetch: oneTaskrefetch,  error, isLoading } = useGetOneTaskQuery(
        { id, update: true }
    );

    const [updatetask] = useUpdateTaskOneMutation();
    const [updateTaskStatus] = useUpdateTaskTypeStatusMutation();

    

    useEffect(() => {
            oneTaskrefetch();  
        return () => {
            localStorage.removeItem('uuids');
            localStorage.removeItem('frontId');
        };
    }, []);

    const taskData = task?.data
    const color = userCompany?.button_color || 'rgba(164, 54, 109, 1)'

    const [showInfo, setShowInfo] = useState(true);
    const [showEditor, setShowEditor] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState<any>(taskData?.status ? { value: taskData.status.id, label: taskData.status.name } : null);
    const [popUpMessage, setPopUpMessage] = useState(false);
    const [showCustomFields, setShowCustomFields] = useState('info');
    const[activeTab, setActiveTab] = useState('info');
    
    useEffect(() => {
        if (popUpMessage) {
          const timer = setTimeout(() => {
            setPopUpMessage(false);
          }, 3000);
      
          return () => clearTimeout(timer); 
        }
    }, [popUpMessage]);
        


    const initialValues = {
        content: task?.data?.content || '',
        customFields: taskData?.custom_fields?.reduce((acc: any, field: any) => {
          const entityValue = taskData?.custom_field_entity_values?.find((cf: any) => cf.custom_field_id === field.id)?.value
          const fieldValue = taskData?.custom_field_values?.find((cf: any) => cf.custom_field_id === field.id)?.value

          switch (field.field_type) {
            case DROPDOW_OPTIONS.USER:
            case DROPDOW_OPTIONS.DEPARTMENT:
            case DROPDOW_OPTIONS.OFFICE:
            case DROPDOW_OPTIONS.JOB_TITLE:
            case DROPDOW_OPTIONS.PROJECT_GROUP:
                acc[field.id] = entityValue ? Array.isArray(entityValue) ? entityValue.map((val: any) => ({ value: val.id, label: val.name })) : { value: entityValue.id, label: entityValue.name } : null
              break
            case TASK_FORM_OPTIONS.DROPDOWN: 
                acc[field.id] = fieldValue ? fieldValue.map((val: any) => ({
                  value: val.id,
                  label: taskData?.custom_fields?.find((cf: any) => cf.id === field.id)?.options?.find((option: any) => option.id === val.id)?.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_value
                })) : []
              break
            case INPUT_FILED.STRING:
            case INPUT_FILED.NUMBER:
              acc[field.id] = fieldValue || ""
              break
            case DATE_OPTIONS.DATE:
                if (fieldValue && typeof fieldValue === 'object' && fieldValue.date) {
                acc[field.id] = fieldValue.date ||  null
                }
              break
            case EDITOR.EDITOR:
              acc[field.id] = fieldValue || ''
              break
            default:
              break
          }
          return acc
        }, {}) || {}
    };

    useEffect(() => {
        if (taskData?.custom_field_values?.some((field: any) => field.entity_files)) {
          const backUID = localStorage.getItem('uuids');
          const prevUUIDs = backUID ? JSON.parse(backUID) : [];
      
          const newUUIDs = task.data.custom_field_values
            .filter((field: any) => field.entity_files)
            .flatMap((field: any) => field.entity_files.map((file: any) => file.uuid));
          const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
      
          localStorage.setItem('uuids', JSON.stringify(combinedUUIDs));
        }
      }, [taskData?.data?.custom_field_values]);
    

    const mapOptions = (optionsData: any) => optionsData?.data?.map((option: any) => ({
        value: option.id,
        label: option.name || option.fullname,
    }));

    const { data: userOptions } = useGetOptionValueCustomFieldsQuery(
        { field_type: DROPDOW_OPTIONS.USER },
        {
            skip: !taskData?.custom_fields?.some((field: any) =>
                field.field_type === DROPDOW_OPTIONS.USER)
        }
    );

    const { data: departmentOptions } = useGetOptionValueCustomFieldsQuery(
        { field_type: DROPDOW_OPTIONS.DEPARTMENT },
        {
            skip: !taskData?.custom_fields?.some((field: any) =>
                field.field_type === DROPDOW_OPTIONS.DEPARTMENT)
        }
    );

    const { data: officeOptions } = useGetOptionValueCustomFieldsQuery(
        { field_type: DROPDOW_OPTIONS.OFFICE },
        {
            skip: !taskData?.custom_fields?.some((field: any) =>
                field.field_type === DROPDOW_OPTIONS.OFFICE)
        }
    );

    const { data: jobTitleOptions } = useGetOptionValueCustomFieldsQuery(
        { field_type: DROPDOW_OPTIONS.JOB_TITLE },
        {
            skip: !taskData?.custom_fields?.some((field: any) =>
                field.field_type === DROPDOW_OPTIONS.JOB_TITLE)
        }
    );

    const { data: projectGroupOptions } = useGetOptionValueCustomFieldsQuery(
        { field_type: DROPDOW_OPTIONS.PROJECT_GROUP },
        {
            skip: !taskData?.custom_fields?.some((field: any) =>
                field.field_type === DROPDOW_OPTIONS.PROJECT_GROUP)
        }
    );

    const optionsUser = mapOptions(userOptions);
    const optionsDepartment = mapOptions(departmentOptions);
    const optionsOffice = mapOptions(officeOptions);
    const optionsJobTitle = mapOptions(jobTitleOptions);
    const optionsProjectGroup = mapOptions(projectGroupOptions);
    const mapOptionsStatusEdit = (optionsData: any, selectedStatusId: any) => {
        const selectedStatus = optionsData?.find((option: any) => option.id === selectedStatusId);
        if (!selectedStatus) return [];
      
        const previousStatuses = new Map();
        const nextStatuses = new Map();
        const uniqueNames = new Set();
      
        const addStatuses = (statuses: any[], map: Map<any, any>) => {
          statuses.forEach((status: any) => {
            if (!uniqueNames.has(status.name)) {
              uniqueNames.add(status.name);
              map.set(status.id, { value: status.id, label: status.name, bg_color: status.bg_color, font_color: status.font_color });
            }
          });
        };
      
        addStatuses(selectedStatus.previous_statuses || [], previousStatuses);
        addStatuses(selectedStatus.next_statuses || [], nextStatuses);
      
        if (!uniqueNames.has(selectedStatus.name)) {
          uniqueNames.add(selectedStatus.name);
        }
      
        const combinedStatuses = [
          ...Array.from(previousStatuses.values()),
          { value: selectedStatus.id, label: selectedStatus.name, bg_color: selectedStatus.bg_color, font_color: selectedStatus.font_color },
          ...Array.from(nextStatuses.values())
        ];
      
        return combinedStatuses.filter((status, index, self) =>
          index === self.findIndex((s) => s.label === status.label)
        );
      };
    
    useEffect(() => {
        if (taskData?.status?.id) {
            setSelectedStatus({ value: taskData.status.id, label: taskData.status.name });
        }
    }, [taskData?.status?.id]);
    
    const optionsStatusEdit = useMemo(() => mapOptionsStatusEdit(taskData?.custom_field_statuses, taskData?.status?.id), [taskData?.custom_field_statuses, taskData?.status?.id]);
    
    const dropdownOptions = useMemo(() => {
        return taskData?.custom_fields?.reduce((acc: any, field: any) => {
            if (field.field_type === TASK_FORM_OPTIONS.DROPDOWN) {
                acc[field.id] = field.options?.map((option: any) => {
                    const translatedValue = option.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_value || '';
                    console.log(option)
                    return { ...option, value: option.id, label: translatedValue };
                }) || [];
            }
            console.log(acc);
            return acc;
        }, {}) || {};
    }, [taskData, intl.locale]);

    if (isLoading) return <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>;
    if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingTask', defaultMessage: 'Error while loading Task' })}</p></div>;


    async function handleStatusChange(id: any) {
        try {
            await updateTaskStatus({ id: taskData.id, status_id: { status_id: id } }).unwrap();
            oneTaskrefetch()
        } catch (error) {
            console.error(error);
        }
    }

    const handleSubmit = async (values: any) => {
        const customFieldValues: any = []
        const customFieldEntityValues: any = []
        const customUID = localStorage.getItem('uuids');
        const frontUID = localStorage.getItem('frontId');
        
        Object.keys(values.customFields).forEach((key) => {
          if (!isNaN(Number(key))) {
              const taskType = taskData?.custom_fields.find((task: any) => task.id === Number(key))
              if (taskType && values.customFields[key] && values.customFields[key] !== '' && taskType.is_output) {
                if (taskType.field_type === DATE_OPTIONS.DATE) {
                  customFieldValues.push({
                    custom_field_id: Number(key),
                    value: values.customFields[key]
                  })
                } else if (taskType.field_type === DROPDOW_OPTIONS.USER ||
                  taskType.field_type === DROPDOW_OPTIONS.DEPARTMENT ||
                  taskType.field_type === DROPDOW_OPTIONS.OFFICE ||
                  taskType.field_type === DROPDOW_OPTIONS.JOB_TITLE ||
                  taskType.field_type === DROPDOW_OPTIONS.PROJECT_GROUP) {
                  const entityValues = Array.isArray(values.customFields[key])
                    ? values.customFields[key].map((item: any) => {
                        const entityValue = task?.data?.custom_field_entity_values?.find((cf: any) => cf.custom_field_id === Number(key))?.value.find((val: any) => val.id === item.value);
                        return { id: item.value, entity_value_id: entityValue?.entity_value_id };
                      })
                    : [{
                        id: values.customFields[key]?.value,
                        entity_value_id: task?.data?.custom_field_entity_values?.find((cf: any) => cf.custom_field_id === Number(key))?.value.find((val: any) => val.id === values.customFields[key]?.value)?.entity_value_id
                      }];
                  if (entityValues.length > 0 && entityValues[0].id) {
                    customFieldEntityValues.push({
                      custom_field_id: Number(key),
                      value: entityValues
                    });
                  }
                } else if (taskType.field_type === TASK_FORM_OPTIONS.DROPDOWN) {
                  const dropdownValues = Array.isArray(values.customFields[key])
                    ? values.customFields[key].map((item: any) => ({ id: item.value }))
                    : [{ id: values.customFields[key]?.value }];
                  if (dropdownValues.length > 0 && dropdownValues[0].id) {
                    customFieldValues.push({
                      custom_field_id: Number(key),
                      value: dropdownValues
                    });
                  }
                } else if (taskType.field_type === EDITOR.EDITOR) {
                  const editorField: any = {
                    custom_field_id: Number(key),
                    value: values.customFields[key]
                  };
                  if (customUID) {
                    try {
                      const parsedBackUID = JSON.parse(customUID);
                      if (Array.isArray(parsedBackUID) && parsedBackUID.length > 0) {
                        editorField.entity_files = {
                          uuid: frontUID || uuidv4(),
                          files: parsedBackUID.map((id: any) => ({
                            id,
                            tag: ""
                          })),
                        };
                      }
                    } catch (error) {
                      console.error('Failed to parse backUID:', error);
                    }
                  }
                  customFieldValues.push(editorField);
                } else if(INPUT_FILED.STRING || INPUT_FILED.NUMBER || DATE_OPTIONS.DATE){
                  customFieldValues.push({
                    custom_field_id: Number(key),
                    value: values.customFields[key]
                  })
                }
              }
          }
        })
        
        if (id) {
          customFieldValues.forEach((field: any) => {
          field.id = task?.data?.custom_field_values?.find((cf: any) => cf.custom_field_id === field.custom_field_id)?.id
          })
          customFieldEntityValues.forEach((field: any) => {
          field.id = task?.data?.custom_field_entity_values?.find((cf: any) => cf.custom_field_id === field.custom_field_id)?.id
          })
        }
        try {
          let data: any = {
            content: values.content,
            creator_id: userInfo?.data?.id,
            assignee_id: taskData?.assignee?.id,
            status_id: selectedStatus?.value,
            custom_field_values: customFieldValues,
            custom_field_entity_values: customFieldEntityValues,
          }
      
          data.status = 'published'
            await updatetask({ id: id, data: data }).unwrap()
            oneTaskrefetch()
            setPopUpMessage(true)
        } catch (error) {
          console.error('Failed to create/update investigation:', error)
        }
      }
    

    const captureTaskPage = async () => {
        const input = document.getElementById('task-page');
        if (input) {
            const canvas = await html2canvas(input);
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('task-page.pdf');
        }
    };

    return (
        <div id="task-page">
            <Container>
                <Header>
                    <Title>{taskData?.title}</Title>
                    <BackLink to={paths.tasks} color={color}>
                        <LuArrowLeft size={12} />{' '}
                        {formatMessage({
                            id: 'task.backTo',
                            defaultMessage: 'BACK TO TASK',
                        })}
                    </BackLink>
                </Header>
                <Content>
                    <div style={{width: '70%'}}>
                        <HtmlView content={taskData?.content || ''} />
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '60%', cursor: 'normal', marginBottom: 40, marginTop: 20 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', height: '40px', justifyContent: 'space-between', border: '0.063rem solid #D2D4D7', borderRadius: '8px', boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)' }}>
                            <ButtonFilter active={activeTab === 'info'} color={userCompany?.button_color} onClick={() => setActiveTab('info')}>
                                {formatMessage({ id: 'task.workLog', defaultMessage: 'Worklog' })}
                            </ButtonFilter>
                            <ButtonFilter active={activeTab === 'history'} color={userCompany?.button_color} onClick={() => { setActiveTab('history') }}>
                                {formatMessage({ id: 'task.history', defaultMessage: 'History' })}
                        </ButtonFilter>
                  </div>
                </div>
                 
                    {activeTab === 'info' && (   
                        <>
                        <ContainerTaskIsInput>
                            {taskData?.custom_fields?.filter((field: any) => field.is_input).map((field: any, index: number, array: any[]) => {
                                const translation = field.translations.find((translation: any) => translation.language_code === intl.locale);
                                const fieldName = translation ? translation.translated_name : 'N/A';
                                let customFieldValue = taskData?.custom_field_values?.find((cfv: any) => cfv.custom_field_id === field.id);

                                let displayValue = '';
                                if (customFieldValue && typeof customFieldValue.value === 'object' && customFieldValue.value !== null && field.field_type === CustomFieldPermisson.DROPDOWN) {
                                    displayValue = customFieldValue.value.map((val: any) => {
                                        return field.options.find((option: any) => option.id === val.id)?.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_value || 'N/A';
                                    }).join(', ');
                                } else if (customFieldValue && (field.field_type === CustomFieldPermisson.STRING || field.field_type === CustomFieldPermisson.NUMBER)) {
                                    displayValue = customFieldValue?.value || 'N/A';
                                } else if (customFieldValue && field.field_type === CustomFieldPermisson.DATE) {
                                    displayValue = new Date(customFieldValue?.value.date).toLocaleDateString('en-CA') || 'N/A';
                                } else {
                                    const value = taskData?.custom_field_entity_values.find((cfv: any) => cfv.custom_field_id === field.id)?.value;
                                    displayValue = Array.isArray(value) ? value.map((v: any) => v.name).join(', ') : value?.name || 'N/A';
                                }                       
                                return (
                                    <RowIsInput key={field.id} style={{ fontWeight: 500, borderRadius: index === array.length - 1 ? 15 : 0, borderTopLeftRadius: index === 0 ? 15 : 0, borderTopRightRadius: index === 0 ? 15: 0, paddingTop: index === 0 ? '20px' : '15px', paddingBottom: index === array.length - 1 ? '25px' : '20px' }}>
                                        <FieldValue>{fieldName || 'N/A'}</FieldValue>
                                        <FieldValue>{displayValue || 'N/A'}</FieldValue> 
                                    </RowIsInput>
                                );
                            })}
                        </ContainerTaskIsInput>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                        >
                            {({ values,  handleChange, setFieldValue }) => (
                                <Form>
                                    <ContainerTaskIsInput style={{marginTop: 50}}>
                                    {taskData?.custom_fields
                                    ?.filter((field: any) => field.is_output)
                                    .filter((field: any) => field.field_type !== EDITOR.EDITOR).map((field: any, index: number, array: any[]) => {
                                            const fieldName = field.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name || 'N/A';
                                            const optionsDropdown = dropdownOptions[field.id] || [];
                                            if (field.field_type === EDITOR.EDITOR) return null;
                                            

                                            return (
                                                <RowIsInput key={field.id} style={{
                                                    borderRadius: index === array.length - 1 ? 15 : 0,
                                                    borderTopLeftRadius: index === 0 ? 15 : 0,
                                                    borderTopRightRadius: index === 0 ? 15 : 0,
                                                    paddingTop: index === 0 ? 20 : 15,
                                                    paddingBottom: index === array.length - 1 ? 25 : 20,
                                                    borderBottomLeftRadius: index === array.length - 1 ? 15 : 0,
                                                    borderBottomRightRadius: index === array.length - 1 ? 15 : 0,
                                                }}>
                                                        <>
                                                            {field.field_type === INPUT_FILED.STRING || field.field_type === INPUT_FILED.NUMBER ? (
                                                             <>                    
                                                                <FieldValue>{fieldName}</FieldValue>
                                                                <EditableField
                                                                    type="text"
                                                                    name={`customFields.${field.id}`}
                                                                    value={values.customFields[field.id]}
                                                                    color={color}
                                                                    onChange={handleChange}
                                                                    placeholder={`${formatMessage({ id: 'task.enter', defaultMessage: 'Enter' })} ${fieldName.toLowerCase()}...`}
                                                                />
                                                            </>
                                                            ) : field.field_type === TASK_FORM_OPTIONS.DROPDOWN ? (
                                                                    field.is_multiselect ? (
                                                                    <>                    
                                                                        <FieldValue>{fieldName}</FieldValue>
                                                                    <CustomSelectMultiContainer>
                                                                            <CustomSelectMulti
                                                                            label={fieldName}     
                                                                            value={values.customFields[field.id]}
                                                                            options={optionsDropdown}
                                                                            onChange={(selectedOption) => setFieldValue(`customFields.${field.id}`, selectedOption)}
                                                                            placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${fieldName.toLowerCase()}...`}
                                                                        />
                                                                    </CustomSelectMultiContainer>
                                                                    </>
                                                                    ) : (
                                                                    <>                    
                                                                        <FieldValue>{fieldName}</FieldValue>
                                                                    <CustomSelectMultiContainer>
                                                                        <CustomSelect
                                                                        label={fieldName}     
                                                                        value={values.customFields[field.id]}
                                                                        options={optionsDropdown}
                                                                        onChange={(selectedOption) => setFieldValue(`customFields.${field.id}`, selectedOption)}
                                                                        placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${fieldName.toLowerCase()}...`}
                                                                                />
                                                                    </CustomSelectMultiContainer>
                                                                    </>
                                                                )
                                                                ) : field.field_type === DATE_OPTIONS.DATE ? (
                                                                <>                    
                                                                    <FieldValue>{fieldName}</FieldValue>
                                                                <DatePicker
                                                                    selected={values.customFields[field.id] || undefined}
                                                                    onChange={(date) => setFieldValue(`customFields.${field.id}`, date)}
                                                                    dateFormat="yyyy-MM-dd"
                                                                    autoComplete="off"
                                                                    placeholderText="yyyy-MM-dd"
                                                                    className={styles.datePicker}
                                                                    calendarClassName={styles.calendar}
                                                                    onFocus={(e) => e.target.style.borderColor = userCompany?.button_color}
                                                                    onBlur={(e) => e.target.style.borderColor = ''}
                                                                />
                                                                </>
                                                            ) : (
                                                                (field.field_type === DROPDOW_OPTIONS.USER || 
                                                                field.field_type === DROPDOW_OPTIONS.DEPARTMENT ||
                                                                field.field_type === DROPDOW_OPTIONS.OFFICE ||
                                                                field.field_type === DROPDOW_OPTIONS.JOB_TITLE ||
                                                                field.field_type === DROPDOW_OPTIONS.PROJECT_GROUP) && (
                                                                field.is_multiselect ? (
                                                                    <>                    
                                                                        <FieldValue>{fieldName}</FieldValue>
                                                                        <CustomSelectMultiContainer>
                                                                         <CustomSelectMulti
                                                                                label={fieldName}     
                                                                                value={values.customFields[field.id]}
                                                                                options={field.field_type === DROPDOW_OPTIONS.USER ? optionsUser : field.field_type === DROPDOW_OPTIONS.DEPARTMENT ? optionsDepartment : field.field_type === DROPDOW_OPTIONS.OFFICE ? optionsOffice : field.field_type === DROPDOW_OPTIONS.JOB_TITLE ? optionsJobTitle : optionsProjectGroup}
                                                                                onChange={(selectedOption) => setFieldValue(`customFields.${field.id}`, selectedOption)}
                                                                                placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${fieldName.toLowerCase()}...`}
                                                                            />
                                                                        </CustomSelectMultiContainer>
                                                                    </>
                                                                    ) : (
                                                                        <>                    
                                                                        <FieldValue>{fieldName}</FieldValue>
                                                                        <CustomSelectMultiContainer>
                                                                            <CustomSelect
                                                                                label={fieldName}                    
                                                                                value={values.customFields[field.id]}
                                                                                options={field.field_type === DROPDOW_OPTIONS.USER ? optionsUser : field.field_type === DROPDOW_OPTIONS.DEPARTMENT ? optionsDepartment : field.field_type === DROPDOW_OPTIONS.OFFICE ? optionsOffice : field.field_type === DROPDOW_OPTIONS.JOB_TITLE ? optionsJobTitle : optionsProjectGroup}
                                                                                onChange={(selectedOption) => setFieldValue(`customFields.${field.id}`, selectedOption)}
                                                                                placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${fieldName.toLowerCase()}...`}
                                                                            />
                                                                        </CustomSelectMultiContainer>
                                                                    </>
                                                                    )
                                                                )
                                                            )}
                                                        </>                                              
                                                </RowIsInput>
                                            );
                                        })}
                                    </ContainerTaskIsInput>
                                            

                                    {taskData?.custom_fields?.filter((field: any) => field.is_output && field.field_type === EDITOR.EDITOR).map((field: any) => {
                                        const fieldName = field.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name || 'N/A';

                                        return (
                                            <div key={field.id} style={{marginTop: 20, width: '80%'}}>
                                                <Label style={{textAlign: 'left', marginBottom: '0.5rem'}}>{fieldName}</Label>
                                                <WangEditorTask name={`customFields.${field.id}`} />
                                            </div>
                                        );
                                    })}
                                    <ButtonSecondary type="submit" style={{marginTop: 20}}>{formatMessage({ id: 'task.save', defaultMessage: 'Save' })}</ButtonSecondary>
                                </Form>
                            )}
                            
                        </Formik>
                    </>
                    )}
                        {activeTab === 'history' && (
                            <div>
                                {taskData?.histories?.map((history: any) => (
                                <div key={history.id} style={{margin: 20}}>
                                    <InfoSection>
                                    <UserInfo>
                                        <UserAvatar style={{background: color, alignSelf: 'flex-start'}}>
                                            <UserInitials>
                                                {history?.user?.fullname.split(' ').map((name: any) => name.charAt(0)).join('') || 'UN'} 
                                            </UserInitials>
                                                </UserAvatar>
                                            <div style={{flexDirection: 'column', marginLeft: 5, maxWidth: '80%'}}>
                                            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                                <UserName>{history?.user?.fullname || 'Unknown'}</UserName>
                                                <p style={{ padding: 5 }}>{formatMessage({ id: 'task.has', defaultMessage: 'has' })}</p>
                                                <UserName>{history?.change_type}</UserName> 
                                                <p style={{padding: 5}}>{history?.created_at}</p>
                                            </div>
                                            {history && (
                                            <div style={{ paddingLeft: 5, color: 'grey', fontSize: 14, wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: intl.locale === 'sv' ? history.comment_sv : history.comment_en }} />
                                            )}
                                            </div>
                                        </UserInfo>                                                           
                                    </InfoSection>
                                        
                                </div>
                                ))}
                            </div>
                        )}                        
                    </div>       
                    <div>
                        
                        <TaskType>{formatMessage({ id: 'task.taskType', defaultMessage: 'Task type' })}: {taskData?.task_type.name}</TaskType>
                    <DropdownStatus
                            label={'Status'}
                            value={selectedStatus}
                            options={optionsStatusEdit}
                            onChange={(selectedOption) => {
                                setSelectedStatus(selectedOption)
                                handleStatusChange(selectedOption.value)
                            }}
                            placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} status...`}
                        />      
                 
                        <InfoContainer>
                            <InfoButton onClick={()=>{setShowInfo(!showInfo)}}>
                                <InfoTitle>Information</InfoTitle>
                                {showInfo ? <RiArrowDropUpLine size={30} color='grey' /> : <RiArrowDropDownLine size={30} color='grey' />}
                            </InfoButton>
                            {showInfo && (
                                <>                                   
                                    {(taskData?.is_overdue || taskData?.is_archived) && (<div style={{ display: 'flex', flexDirection: 'row' }}>
                                        {taskData?.is_overdue && (
                                            <InfoSection style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center', marginTop: 15, marginBottom: 2 }}>
                                                <div style={{ width: 10, height: 10, backgroundColor: 'red', borderRadius: '50%' }} />
                                                <p style={{ color: 'black', textTransform: 'uppercase', fontSize: 12 }}>{formatMessage({ id: 'task.overdue', defaultMessage: 'OVERDUE' })}</p>
                                            </InfoSection>)}

                                        {taskData?.is_archived && (
                                            <InfoSection style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center',  marginTop: 15, marginBottom: 2 }}>
                                                <div style={{ width: 10, height: 10, backgroundColor: '#926900', borderRadius: '50%' }} />
                                                <p style={{ color: 'black', textTransform: 'uppercase', fontSize: 12 }}>{formatMessage({ id: 'task.archived', defaultMessage: 'ARCHIVED' })}</p>
                                            </InfoSection>)}
                                    </div>)}

                                <InfoSection>
                                    <p style={{color: 'grey', fontWeight: '500'}}>{formatMessage({ id: 'task.assigne', defaultMessage: 'Assigne' })}</p>
                                    <UserInfo>
                                        <UserAvatarInfo style={{background: color}}>
                                            <UserInitialsInfo>
                                                {taskData?.assignee.fullname.split(' ').map((name: any) => name.charAt(0)).join('')}
                                            </UserInitialsInfo>
                                        </UserAvatarInfo>
                                        <UserNameInfo>{taskData?.assignee.fullname}</UserNameInfo>
                                    </UserInfo>
                                </InfoSection>
                                <InfoSection>
                                        <p style={{ color: 'grey', fontWeight: '500' }}>{formatMessage({ id: 'task.creator', defaultMessage: 'Created by: ' })}</p>
                                    <UserInfo>
                                        <UserAvatarInfo style={{background: color}}>
                                            <UserInitialsInfo>
                                                {taskData?.creator.fullname.split(' ').map((name: any) => name.charAt(0)).join('')}
                                            </UserInitialsInfo>
                                        </UserAvatarInfo>
                                        <UserNameInfo>{taskData?.creator?.fullname}</UserNameInfo>
                                        </UserInfo>
                                    </InfoSection>

                                    <InfoSection>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15, gap: 5 }}>
                                        <p style={{color: 'grey', fontWeight: '500'}}>Status:</p>
                                        <Status style={{ backgroundColor: taskData?.status?.bg_color, color: taskData?.status?.font_color }}>{taskData?.status?.name}</Status> 
                                    </div>
                                    </InfoSection>
                            </>
                            )}
                        </InfoContainer>
                        <Dates>
                            {taskData?.updated_at && <p>{formatMessage({ id: 'task.updateAt', defaultMessage: 'Updated at: ' })} {taskData?.updated_at}</p>}
                            {taskData?.created_at && <p>{formatMessage({ id: 'task.createAT', defaultMessage: 'Created at: ' })} {taskData?.created_at}</p>}
                        </Dates>
                    </div>
                    
                </Content>
                <Footer>
                     {taskData?.completed_date && <p style={{ color: 'green' }}>{formatMessage({ id: 'task.completedDate', defaultMessage: 'Completed date:' })} {taskData?.completed_date}</p>}
                     {taskData?.due_date && <p style={{ color: 'red' }}>{formatMessage({ id: 'task.dueDate', defaultMessage: 'Due Date' })}: {taskData?.due_date}</p>}
                </Footer>
                <ButtonSecondary onClick={captureTaskPage}>
                    {formatMessage({ id: 'task.downloadPDF', defaultMessage: 'Download as PDF' })}
                </ButtonSecondary>
            </Container>
            {popUpMessage && <Toast message={formatMessage({ id: 'task.saved', defaultMessage: 'Saved!' })} />}
        </div>
    );
};


  /* 
       const captureTaskPage = async () => {
        const pdf = new jsPDF();
        const pageWidth = pdf.internal.pageSize.getWidth();
        const margin = 10;
        const maxLineWidth = pageWidth - margin * 2;

        const title = taskData?.title || '';
        const content = taskData?.content || '';
        const taskType = taskData?.task_type?.name || '';
        const logoUrl = userCompany?.logo?.url || '';

        const addTextContent = () => {
            const titleLines = pdf.splitTextToSize(`Title: ${title}`, maxLineWidth);
            const contentLines = pdf.splitTextToSize(`Content: ${content}`, maxLineWidth);

            let y = margin + 30; // Adjust starting position to account for logo
            pdf.text(titleLines, margin, y);
            y += titleLines.length * 10; // Adjust line height as needed
            pdf.text(contentLines, margin, y);

            // Add task type on the right side
            pdf.text(`Task type: ${taskType}`, pageWidth - margin - pdf.getTextWidth(`Task type: ${taskType}`), margin + 10);

            pdf.save('task-page.pdf');
        };

        // Add logo
        if (logoUrl) {
            const img = new Image();
            img.crossOrigin = 'Anonymous'; // Enable CORS
            img.src = logoUrl;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(img, 0, 0);
                const dataURL = canvas.toDataURL('image/png');
                pdf.addImage(dataURL, 'PNG', margin, margin, 50, 20); // Adjust size and position as needed
                addTextContent();
            };
            img.onerror = () => {
                console.error('Error loading logo image');
                addTextContent();
            };
        } else {
            addTextContent();
        }
    }; */

    /* const captureTaskPage = async () => {
    const pdf = new jsPDF();
    const pageWidth = pdf.internal.pageSize.getWidth();
    const margin = 10;
    const maxLineWidth = pageWidth - margin * 2;
    const sanitizeContent = (htmlContent: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        return doc.body.innerHTML || '';
    };

    const content =  sanitizeContent(taskData?.content) || '';
    const taskType = taskData?.task_type?.name || '';
    const logoUrl = userCompany?.logo?.url || '';


    // Process custom fields and categorize them into is_input and is_output
    const customFields = taskData?.custom_fields?.reduce((acc: any, field: any) => {
        const entityValue = taskData?.custom_field_entity_values?.find(
            (cf: any) => cf.custom_field_id === field.id
        )?.value;
        const fieldValue = taskData?.custom_field_values?.find(
            (cf: any) => cf.custom_field_id === field.id
        )?.value;

        const value = (() => {
            switch (field.field_type) {
                case DROPDOW_OPTIONS.USER:
                case DROPDOW_OPTIONS.DEPARTMENT:
                case DROPDOW_OPTIONS.OFFICE:
                case DROPDOW_OPTIONS.JOB_TITLE:
                case DROPDOW_OPTIONS.PROJECT_GROUP:
                    return entityValue
                        ? Array.isArray(entityValue)
                            ? entityValue.map((val: any) => val.name).join(', ')
                            : entityValue.name
                        : 'N/A';

                case TASK_FORM_OPTIONS.DROPDOWN:
                    return fieldValue
                        ? fieldValue
                              .map((val: any) =>
                                  taskData?.custom_fields
                                      ?.find((cf: any) => cf.id === field.id)
                                      ?.options?.find((option: any) => option.id === val.id)
                                      ?.translations?.find(
                                          (translation: any) => translation.language_code === intl.locale
                                      )?.translated_value
                              )
                              .join(', ')
                        : 'N/A';

                case INPUT_FILED.STRING:
                case INPUT_FILED.NUMBER:
                    return fieldValue || 'N/A';

                case DATE_OPTIONS.DATE:
                    return fieldValue?.date || 'N/A';

                case EDITOR.EDITOR:
                    return fieldValue || 'N/A';

                default:
                    return 'N/A';
            }
        })();

        if (field.is_input) {
            acc.is_input[field.id] = value;
        } else if (field.is_output) {
            acc.is_output[field.id] = value;
        }

        return acc;
    }, { is_input: {}, is_output: {} }) || {};

    const addTextContent = () => {
        const contentLines = pdf.splitTextToSize(content, maxLineWidth);

        let y = margin + 40; // Adjust starting position to account for logo
        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'normal');
        pdf.text(contentLines, margin, y);

        y += contentLines.length * 10;

        const addCustomFields = (fields: any, title: string) => {
            if (Object.keys(fields).length > 0) {
                y += 15; // Add space before custom fields
                pdf.setFont('helvetica', 'bold');
                pdf.text(title, margin, y);
                y += 10;

                pdf.setFont('helvetica', 'normal');
                Object.entries(fields).forEach(([fieldId, value]) => {
                    // Convert fieldId to number for comparison with custom_field.id
                    const field = taskData?.custom_fields?.find(
                        (customField: any) => customField.id === Number(fieldId) // Ensure type match
                    );

                    // Extract the translated name if available
                    const fieldName = field?.translations?.find(
                        (translation: any) => translation.language_code === intl.locale
                    )?.translated_name;

                    // Split and render the field name and value
                    const displayValue = field.field_type === DATE_OPTIONS.DATE && value ? new Date(value as any).toLocaleDateString('en-CA') : value;
                    const fieldLines = pdf.splitTextToSize(`${fieldName}: ${displayValue}`, maxLineWidth);
                    pdf.text(fieldLines, margin, y);
                    y += fieldLines.length * 10; // Adjust line height
                });
            }
        };

        addCustomFields(customFields.is_input, 'Input Fields');
        addCustomFields(customFields.is_output, 'Output Fields');


        pdf.save('task-page.pdf');
    };

    // Add logo
    const img = new Image();
    img.crossOrigin = ''; // Enable CORS
    img.src = logoUrl;
    img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        pdf.addImage(dataURL, 'PNG', margin, margin, 50, 20); // Adjust size and position

        // Move task type to the right side at the top
        pdf.setFont('helvetica', 'italic');
        pdf.text(
            `${taskType}`,
            pageWidth - margin - pdf.getTextWidth(`${taskType}`),
            margin + 10
        );

        addTextContent();
    };
    img.onerror = () => {
        console.error('Error loading logo image');
        
        // Move task type to the right side at the top
        pdf.setFont('helvetica', 'italic');
        pdf.text(
            `${taskType}`,
            pageWidth - margin - pdf.getTextWidth(`${taskType}`),
            margin + 10
        );

        addTextContent();
    };
}; */
