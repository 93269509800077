import React, { useState, useEffect, useCallback } from 'react'
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import {
  IDomEditor,
  IEditorConfig,
  IToolbarConfig,
  i18nChangeLanguage,
  Boot,
  SlateTransforms,
} from '@wangeditor/editor'
import { useField, useFormikContext } from 'formik'
import { v4 as uuidv4 } from 'uuid'

interface CustomDomEditor extends IDomEditor {
  customData?: {
    front_uuid: string
  }
}

interface WangEditorProps {
  name: string
}

const WangEditor: React.FC<WangEditorProps> = ({ name }) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)
  const [editor, setEditor] = useState<CustomDomEditor | null>(null)
  const [html, setHtml] = useState(field.value || '')
  const [frontUuid, setFrontUuid] = useState('');

  i18nChangeLanguage('en')

  useEffect(() => {
      const existingFrontId = localStorage.getItem('frontId');
      if (!existingFrontId) {
          const newUuid = uuidv4();
          setFrontUuid(newUuid);
          localStorage.setItem('frontId', newUuid);
      } else {
          setFrontUuid(existingFrontId);
      }
  }, []);

  const handleChange = (editor: CustomDomEditor) => {
    const content = editor.getHtml()
    setHtml(content)
    setFieldValue(name, content)
  }

  const toolbarConfig: Partial<IToolbarConfig> = {
    excludeKeys: ['insertLink', 'insertVideo', 'uploadVideo', 'group-image', 'group-video', 'emotion', 'codeBlock', 'divider'],
  }

  const editorConfig: Partial<IEditorConfig> = {
   
  }


  useEffect(() => {
  }, [])

  useEffect(() => {
    if (editor) {
      editor.customData = { front_uuid: frontUuid }
      if (field.value !== editor.getHtml()) {
        editor.setHtml(field.value || '')
      }
    }
  }, [field.value, editor, frontUuid])

  useEffect(() => {
    return () => {
      if (editor) {
        editor.destroy()
        setEditor(null)
      }
    }
  }, [editor])

/*   useEffect(() => {
    console.log('Available toolbar keys:', Boot?.toolbarConfig?.toolbarKeys)
  }, []) */

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '1320px',
        overflowX: 'visible',
        zIndex: 9998,
      }}
    >
      <div style={{ border: '1px solid #ccc', zIndex: 9999 }}>
        <Toolbar
          editor={editor}
          defaultConfig={toolbarConfig}
          mode="default"
          style={{ borderBottom: '1px solid #ccc', zIndex: 9999 }}
        />
        <Editor
          defaultConfig={editorConfig}
          value={html}
          onCreated={setEditor}
          onChange={handleChange}
          mode="default"
          style={{
            height: '400px',
            overflowY: 'hidden',
            width: '100%',
            zIndex: 9998,
          }}
        />
      </div>
      {meta.touched && meta.error ? (
        <div style={{ color: 'red' }}>{meta.error}</div>
      ) : null}
    </div>
  )
}

export default WangEditor
