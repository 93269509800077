import styled from 'styled-components';
//added Zindex to fix the issue of the modal dropdwon compnay not being on top of the components
export const FormPaper = styled.div`
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    `;

export const FormHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size:1.8rem;
    `;

export const Actions = styled.div`
    display: flex;
    gap: 10px;
`;

export const TabContent = styled.div`
    display: flex;
`;

export const FormContent = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 9997;
`;

export const ColumnTopLevel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 9999;
`;

export const ButtonCreateTaskTypes = styled.button`
  display: 'flex',	
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: 'transparent',
  border: 'transparent',
  cursor: 'pointer',
  width: '180px',
  maxWidth: '180px',
  height: '50px',
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
`;


export const TaskContainer = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: space-between;
`;

export const TaskTypeCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ListContainer = styled.div`
    width: 100%;
    overflow-x: auto;
`

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

export const Thead = styled.thead`
    background-color: #efefef;
    color: #000;
`

export const Tbody = styled.tbody`
    background-color: #fff;
`

export const Tr = styled.tr`
    &:nth-child(even) {
        background-color: #f9f9f9;
    }
`

export const Th = styled.th`
    padding: 10px;
    text-align: left;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
`

export const Td = styled.td`
  vertical-align: middle;
  padding: 10px;
  border-bottom: 1px solid #ddd;
`

export const TdButton = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
`
export const TdText = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
`


export const ButtonIcon = styled.button`
  background: none;
  padding: 3px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`
