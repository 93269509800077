// src/features/Profile/components/ProfileDetails.tsx

import React, { useEffect, useState } from 'react';
import { useIntlContext } from '../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../locale';
import Button from '../../../ui-kit/Button/Button';
import { useGetUserInfoQuery } from '../../../slices/usersApiSlices';
import { ProfileInfo } from './ProfileInfo';
import '@fontsource/roboto/500.css';
import { PopUpChangePassword } from './PopUpChangePassword';
import './Css/ProfileDetails2.css';
import { useSelector } from 'react-redux';
import { hexToRGBA } from '../../../ui-kit/utils/hexToRGBA';


export const ProfileDetails: React.FC = () => {
  const { userCompany } = useSelector((state: any) => state.auth);
  const { intl } = useIntlContext();
  const [isPopUpVisible, setIsPopUpVisible] = useState<Boolean>(false);

  const { data: user, refetch, error, isLoading } = useGetUserInfoQuery({});

  const togglePopUp = () => {
    setIsPopUpVisible(!isPopUpVisible);
  };
  const color = userCompany?.main_color 
  ? hexToRGBA(userCompany?.main_color, 0.1) 
    : 'rgba(164, 54, 109, 0.1)';

  useEffect(() => {
    refetch();
  }, [intl.locale]);
  

  


  if (isLoading) return <div>{formatMessage({ id: 'loading', defaultMessage: 'Loading...' })}</div>;
  if (error) return <div>{formatMessage({ id: 'errorLoading', defaultMessage: 'Error loading user details' })}</div>;

  return (
    <div className="profile-container">
      <div className="profile-header">
        <div className="profile-avatar">
          <p className="profile-avatar-text" style={{
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
          }}>{user?.data?.firstname[0]?.toUpperCase()}{user?.data?.lastname[0]?.toUpperCase()}</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 65, alignSelf: 'center', marginLeft: '10px' }}>
          <p className="profile-name" style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 500 }}>
            {(user?.data?.firstname?.charAt(0).toUpperCase() ?? '') + (user?.data?.firstname?.slice(1) ?? '')} {(user?.data?.lastname?.charAt(0).toUpperCase() ?? '') + (user?.data?.lastname?.slice(1) ?? '')}
          </p>

          <div style={{ display: 'flex', backgroundColor: color, padding: 5, borderRadius: 8, gap: 5, justifyContent: 'center' }}>
            <div style={{ backgroundColor: userCompany?.main_color || 'rgba(164, 54, 109, 1)', height: 10, width: 10, alignSelf: 'center', borderRadius: 5 }} />
            <p style={{ fontSize: 14, margin: 0 }}>{(user?.data?.role as any)?.title ?? ''}</p>
          </div>
        </div>
      </div>

      <Button onClick={togglePopUp}>
        <p>{intl.locale === 'en' ? 'CHANGE PASSWORD' : 'ÄNDRA LÖSENORD' }</p>
      </Button>

      <div className="profile-border" />

      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '90%', marginTop: 20 }}>
        <div >
          <ProfileInfo label="Email" value={user?.data?.email} />
          <ProfileInfo label="Language" value={(user?.data as any).locale.toUpperCase()} />
        </div>
        <div>
          <ProfileInfo label="Department" value={user?.data?.department?.name} />
          <ProfileInfo label="Office" value={user?.data?.office?.name} />
        </div>

        <div>
          <ProfileInfo label="JobTitle" value={user?.data?.job_title?.name} />
          <ProfileInfo label="ProjectGroup" value={user?.data?.project_group?.name} />
        </div>
      </div>

      {isPopUpVisible && (
        <PopUpChangePassword onClose={togglePopUp} />
      )}
    </div>
  );
};
