import React, { useState } from 'react';
import { LuX } from 'react-icons/lu';
import {
  Overlay,
  Modal,
  CloseButton,
  Form,
  Label,
  Button,
  Message,
  ScrollMessages
} from './CopyPopup.styles';
import { useIntlContext } from '../../../../locale/IntlProviderWrapper';
import { useGetAllCompaniesQuery } from '../../../../slices/companiesApiSlice';
import { formatMessage } from '../../../../locale';
import { MultiSelect } from '../../../../ui-kit/MultiSelect/MultiSelect';
import { useCopyTaskTypeToOtherCompanyMutation } from '../../../../slices/taskTypeApiSlice';

interface CopyPopupProps {
  Id: number;
  onClose: () => void;
  onCopySuccess: () => void;
}

const CopyPopUp: React.FC<CopyPopupProps> = ({ Id, onClose, onCopySuccess }) => {
  const { intl } = useIntlContext();
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [messages, setMessages] = useState<{ success: boolean; text: string }[]>([]);
  const { data: companies, error, isLoading } = useGetAllCompaniesQuery({});

  const [copyTaskTypeToOtherCompany] = useCopyTaskTypeToOtherCompanyMutation();
  const handleCopy = async () => {
    const newMessages = [];
    let companiesMap: any = {};

    companies?.data?.forEach((c: any) => { companiesMap[c.id] = c.name });

    for (const companyId of selectedCompanies) {
      try {
        await copyTaskTypeToOtherCompany({ id: Id, companyId }).unwrap();
        newMessages.push({ 
          success: true, 
          text: `${formatMessage({ id: 'taskType.to', defaultMessage: 'To ' })}${companiesMap[companyId]}${formatMessage({ id: 'taskType.wasSuccessfullyCopied', defaultMessage: ' was successfully copied.' })}` 
        });
      } catch (err: any) {
        newMessages.push({ success: false, text: `${formatMessage({ id: 'taskType.to', defaultMessage: 'To ' })}${companiesMap[companyId]}${formatMessage({ id: 'taskType.encounteredErrorDuringCopy', defaultMessage: 'encountered an error during copy - ' })} ${err.data.message}` });
      }
    }
    setMessages(newMessages);
    onCopySuccess(); 
  };

  const handleCompanyChange = (field: string, value: string[]) => {
    setSelectedCompanies(value);
  };

  return (
    <Overlay>
      <Modal>
        <CloseButton onClick={onClose}>
          <LuX size={24} />
        </CloseButton>
        <Form>
          <label style={{fontSize: 23, marginBottom: 10}}>{formatMessage({ id: 'courses.copyToCompany', defaultMessage: 'Copy course to other company' })}</label>
            <>
              <MultiSelect
                label={formatMessage({ id: 'courses.multiSelectLabel', defaultMessage: 'Select Companies' })}
                name="selected_companies"
                options={companies?.data?.map((company: any) => ({ value: company.id, label: company.name })) || []}
                values={selectedCompanies}
                onChange={handleCompanyChange}
            />
            <ScrollMessages>
              {messages.map((message, index) => (
                <Message key={index} success={message.success}>
                  {message.text}
                </Message>
              ))}
            </ScrollMessages>
              <Button type="button" onClick={handleCopy}>
                {formatMessage({ id: 'courses.copy', defaultMessage: 'Copy' })}
              </Button>
            </>
        </Form>
      </Modal>
    </Overlay>
  );
};

export default CopyPopUp;
