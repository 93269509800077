import React from 'react';
import Select from 'react-select';
import { StylesConfig } from 'react-select';
import { useSelector } from 'react-redux';

interface CustomSelectProps {
  label: any;
  value: any;
  options: { value: any; label: string; bg_color?: string; font_color?: string }[];
  onChange: (selectedOption: any) => void;
  placeholder: string;
  disabled?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ label, value, options, onChange, placeholder, disabled }) => {
  const { userCompany } = useSelector((state: any) => state.auth)
  const customStyles: StylesConfig = {
      control: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      fontWeight: 500,
      fontSize: 14,
      textTransform: 'capitalize',
      borderRadius: 8,
      marginBottom: 10,
      marginTop: 10,
      padding: 0,
      borderColor: state.isFocused ? userCompany?.button_color : provided.borderColor,
      boxShadow: state.isFocused ? `0 0 0 1px ${userCompany?.button_color}` : provided.boxShadow,
      '&:hover': {
        borderColor: userCompany?.button_color,
      },
    }),
    option: (provided, state) => {
      const option = options.find(opt => opt.value === (state.data as any).value);
      return {
        ...provided,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 15,
        backgroundColor: option?.bg_color || provided.backgroundColor,
        color: option?.font_color || provided.color,
        padding: 10,
        width: '92%',
        margin: 10,
        borderRadius: 8,
        cursor: 'pointer',
        whiteSpace: 'normal', 
        wordBreak: 'break-word',
        overflowX: 'hidden',
        '&:hover': {
          filter: 'brightness(0.75) !important',
        },
      };
    },
    menu: (provided) => ({
      ...provided,
      overflowY: 'auto',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 135,
      "::-webkit-scrollbar": {
        width: "10px",
        height: "2px",
      },
      "::-webkit-scrollbar-track": {
        background: "lightGrey",
        borderRadius: "8px"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "8px"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
        borderRadius: "8px"
      }
    }),
  };

  return (
    <div>
      <Select
      inputId="custom-select"
      value={value}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      isSearchable
      styles={customStyles}
      aria-label={label}
      isDisabled={disabled}
      />
    </div>
  );
};

export default CustomSelect;


/* import React from 'react';
import Select from 'react-select';
import { StylesConfig } from 'react-select';
import { useSelector } from 'react-redux';

interface CustomSelectProps {
  label: any;
  value: any;
  options: { value: any; label: string; bg_color?: string; font_color?: string }[];
  onChange: (selectedOption: any) => void;
  placeholder: string;
  disabled?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ label, value, options, onChange, placeholder, disabled }) => {
  const { userCompany } = useSelector((state: any) => state.auth)
  const customStyles: StylesConfig = {
    control: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      fontWeight: 500,
      fontSize: 14,
      borderRadius: 8,
      marginBottom: 10,
      marginTop: 10,
      borderColor: state.isFocused ? userCompany?.button_color : provided.borderColor,
      boxShadow: state.isFocused ? `0 0 0 1px ${userCompany?.button_color}` : provided.boxShadow,
      '&:hover': {
        borderColor: userCompany?.button_color,
      },
      padding: 0, // Remove padding around the selected value
    }),
    option: (provided, state) => {
      const option = options.find(opt => opt.value === (state.data as any).value);
      return {
        ...provided,
        textAlign: 'center',
        fontWeight: 'normal',
        fontSize: 12,
        backgroundColor: option?.bg_color || provided.backgroundColor,
          color: option?.font_color || provided.color,
          padding: 10,
          width: '90%',
          margin: 10,
          borderRadius: 8,
          cursor: 'pointer',
      };
    },
    menu: (provided) => ({
      ...provided,

      overflowY: 'auto',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 135,
  
      "::-webkit-scrollbar": {
        width: "10px",
        height: "2px",
      },
      "::-webkit-scrollbar-track": {
        background: "lightGrey",
        borderRadius: "8px"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "8px"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
        borderRadius: "8px"
      }
    }),
  };

  return (
    <div>
      <Select
      inputId="custom-select"
      value={value}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      isSearchable
      styles={customStyles}
      aria-label={label}
      isDisabled={disabled}
      menuPosition='fixed'
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      />
    </div>
  );
};

export default CustomSelect; */