import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAllTaskQuery, useGetAllTaskRegularQuery, useDeleteTaskMutation } from '../../../slices/taskApiSlice';
import { CardGrid } from '../../../ui-kit/Card/CardTask';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import PaginableList from '../../../ui-kit/PaginableListDashboard';
import { formatMessage } from '../../../locale'
import { useIntlContext } from '../../../locale/IntlProviderWrapper'
import { TaskPermisson } from '../../../enum/Task/TaskPermisson';
import { Button } from './TaskList.styles';
import { over } from 'lodash';

interface TaskListProps { }

const FilterButtons: React.FC<{ user: any, overdue: any, setOverdue: any, archived: any, setArchived: any }> = ({ user, overdue, setOverdue, archived, setArchived}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', cursor: 'normal', marginBottom: 30 }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '266px', height: '36px', justifyContent: 'space-between', border: '0.063rem solid #D2D4D7', borderRadius: '8px', boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)' }}>
          <Button active={archived === '0'} color={user} style={{ width: 144 }} onClick={() => { setOverdue(false); setArchived("0"); }}>{formatMessage({ id: 'task.active', defaultMessage: 'ACTIVE' })}</Button>
          <Button active={archived === '1'} color={user} style={{ width: 144 }} onClick={() => { setOverdue(false); setArchived("1"); }}>{formatMessage({ id: 'task.archived', defaultMessage: 'ARCHIVED' })}</Button>
        </div>
      </div>
  );
};

export const  TaskList: React.FC<TaskListProps> = () => {
  const { intl } = useIntlContext();
  const navigate = useNavigate();
  const { userInfo, userCompany, permissions } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => permissions && !!permissions[permission];
  const [overdue, setOverdue] = useState<any>(false);
  const [archived, setArchived] = useState<any>("0");
  
  
  const [deleteTask] = useDeleteTaskMutation();
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <PaginableList query={useGetAllTaskQuery} currentPage={currentPage}  overdue={overdue} archived={archived} statusPaginated >
      {(task, isLoading, error, refetch) => {

        if (isLoading) return <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>;
        if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingTask', defaultMessage: 'Error while loading Task' })}</p></div>

        const handleUpdate = (id: number) => {
          navigate(`/task/edit/${id}`);
          refetch();
        };

        const handleDelete = async (id: number) => {
          try {
            await deleteTask({ id }).unwrap();
            console.log(`Task with ID ${id} deleted successfully.`);
            refetch();
          } catch (error) {
            console.error('Error deleting investigation:', error);
          }
        };
        const cardsData = task.map((task: any) => ({
          id: task?.id,
          title: task?.title,
          assignee: task?.assignee,
          creator: task?.creator,
          taskType: task?.task_type,
          dueDate: task?.due_date,
          completedDate: task?.completed_date,
          font_color: task?.status?.font_color,
          background_color: task?.status?.bg_color,
          status: task?.status?.name,
          createdAt: task?.created_at,
          updatedAt: task?.updated_at,
          viewLink: `/task/${task.id}`,
          is_overdue: task?.is_overdue,
          is_archived: task?.is_archived,
          handleUpdate,
          handleDelete,
        }));

        return <>
          <FilterButtons user={userCompany?.button_color} overdue={overdue} setOverdue={setOverdue} archived={archived} setArchived={setArchived} />
          <CardGrid cards={cardsData} />
        </>;
      }}
    </PaginableList>
  );
};

export default TaskList;
