import React, { useEffect, useState } from 'react';
import {
  MainDiv, TaskTypeContainer, Label, Input,
  TextArea, DIV, ModalOverlay, ModalContentWrapper,
  ModalEmptyMessage, TaskTypeItem, Header,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon
} from './ModalContent.style';
import Select, { StylesConfig } from 'react-select';
import { CustomFieldPermisson } from '../../../../enum/TaskPermisson/CustomFieldPermisson';
import CheckBox from '../checkbox/CheckBox';
import { useIntlContext } from '../../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../../locale';
import { useSelector } from 'react-redux';
import { LuPlusCircle } from 'react-icons/lu';
import { Button } from '../../../../ui-kit';
import TooltipContainer from '../../../../ui-kit/ToolTip/TooltipContainer';
import { LuFileEdit, LuTrash } from 'react-icons/lu';
import { MdDone, MdClear } from "react-icons/md";
import ButtonSecondary from '../../../../ui-kit/Button/ui/components/ButtonSecondary' 
import { HideOnMobile } from '../../../../components/BaseLayout/BaseLayout.styles'

interface TaskType {
  mode?: any
  taskTypes?: any
  selectedType?: any
  setSelectedType?: any
  nameTypeEn?: any
  setNameTypeEn?: any,
  nameTypeSv?: any,
  setNameTypeSv?: any,
  optionsDropdown?: any,
  setOptionsDropdown?: any,
  isInput?: any
  setIsInput?: any
  isOutput?: any
  setIsOutput?: any
  isRequired?: any
  setIsRequired?: any
  isMulti?: any
  setIsMulti?: any
  Editor?: any;
  handleAddTaskType?: any;
  listItems?: any;
  setListItems?: any;
}

function ModalContent({ mode, taskTypes, selectedType, setSelectedType, nameTypeEn, setNameTypeEn, nameTypeSv, setNameTypeSv, optionsDropdown, setOptionsDropdown,
  isInput, setIsInput, isOutput, setIsOutput, isRequired, setIsRequired, isMulti, setIsMulti, Editor, listItems, setListItems
 }: TaskType) {
  const { userCompany } = useSelector((state: any) => state.auth)
  const { intl } = useIntlContext();

  // Dropdown options
  const [selectedIdOption, setSelectedIdOption] = useState<any>(null);
  const [addOptionSv, setAddOptionSv] = useState<any>("");
  const [addOptionEn, setAddOptionEn] = useState<any>("");
  const [modeOption, setModeOption] = useState<any>("create");


  //dropdwon options list 
  const [selectedIdOptionModal, setSelectedIdOptionModal] = useState<any>(null);
  const [addOptionSvModal, setAddOptionSvModal] = useState<any>("");
  const [addOptionEnModal, setAddOptionEnModal] = useState<any>("");
  const [optionsDropdownModal, setOptionsDropdownModal] = useState<any>([]);

  // List items
  const [modeModal, setModeModal] = useState<any>('create');  
  const [selectedTypeModal, setSelectedTypeModal] = useState<any>(null);
  const [openList, setOpenList] = useState(false);
  const [openModalAddTaskType, setOpenModalAddTaskType] = useState(false);
  const [addOption, setAddOption] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const [nameTypeSvModal, setNameTypeSvModal] = useState('');
  const [nameTypeEnModal, setNameTypeEnModal] = useState('');
  const [isInputModal, setIsInputModal] = useState(false);
  const [isOutputModal, setIsOutputModal] = useState(false);
  const[isMultiModal, setIsMultiModal] = useState(false);
  const [isRequiredModal, setIsRequiredModal] = useState(false);

  const [optionList] = useState([
    { id: CustomFieldPermisson.DROPDOWN, title: 'Dropdown' },
    { id: CustomFieldPermisson.USER, title: 'User' },
    { id: CustomFieldPermisson.DEPARTMENT, title: 'Department' },
    { id: CustomFieldPermisson.OFFICE, title: 'Office' },
    { id: CustomFieldPermisson.JOB_TITLE, title: 'Job Title' },
    { id: CustomFieldPermisson.PROJECT_GROUP, title: 'Project Group' },
    { id: CustomFieldPermisson.DATE, title: 'Date' },
    { id: CustomFieldPermisson.STRING, title: 'String' },
    { id: CustomFieldPermisson.NUMBER, title: 'Number' },
  ]);

  const optionsListArray = optionList.map((type: any) => ({
    value: type.id,
    label: type.title,
  }));

  const options = taskTypes.map((type: any) => ({
    value: type.id,
    label: type.title,
  }));

  const handleChange = (selectedOption: any) => {
    setSelectedType(selectedOption);
  };

  const handleChangeModal = (selectedOption: any) => {
    setSelectedTypeModal(selectedOption);
  };

  const customStyles: StylesConfig = {
    control: (provided, state) => ({
      ...provided,
      width: 300,
      textAlign: 'left',
      fontWeight: 'normal',
      fontSize: 14,
      borderRadius: 8,
      zIndex: 9999,
      borderColor: state.isFocused ? userCompany?.button_color : provided.borderColor,
      boxShadow: state.isFocused ? `0 0 0 1px ${userCompany?.button_color}` : provided.boxShadow,
      '&:hover': {
        borderColor: userCompany?.button_color,
      },
    }),
    option: (provided) => ({
      ...provided,
      textAlign: 'left',
      fontWeight: 'normal',
      fontSize: 12,
    }),
    menu: (provided) => ({
      ...provided,
      width: 300,
      overflowY: 'auto',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 135,
      width: 300,
      "::-webkit-scrollbar": {
        width: "10px",
        height: "2px",
      },
      "::-webkit-scrollbar-track": {
        background: "lightGrey",
        borderRadius: "8px"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "8px"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
        borderRadius: "8px"
      }
    }),
  };

  const taskTypesList = (type: any) => {
    return [
      CustomFieldPermisson.TaskTypesInArray,
    ].includes(type?.value);
  }

  const isEditorRequired = (type: any) => {
    return [
      CustomFieldPermisson.EDITOR,
    ].includes(type?.value);
  }

  const isDescriptionRequired = (type: any) => {
    return [
      CustomFieldPermisson.DROPDOWN,
    ].includes(type?.value);
  };

  const isMultiselect = (type: any) => {
    return [
      CustomFieldPermisson.DROPDOWN,
      CustomFieldPermisson.DEPARTMENT,
      CustomFieldPermisson.PROJECT_GROUP,
      CustomFieldPermisson.JOB_TITLE,
      CustomFieldPermisson.OFFICE,
      CustomFieldPermisson.USER,
    ].includes(type?.value);
  };

  const generateUniqueId = () => {
    let uniqueId: any;
    do {
      uniqueId = Math.random().toString(36).slice(2, 10);
    } while (listItems.some((customField: any) => customField.id === uniqueId));
    return "tempID" + uniqueId;
  };

  const handleConfirm = () => {
    const newCustomField = {
      id : selectedItem?.id || generateUniqueId(),
      field_type: selectedTypeModal?.value,
      is_input: isInputModal,
      is_output: isOutputModal,
      ...isMultiselect(selectedTypeModal) && {
        is_multiselect: isMultiModal,
      },
      is_required: isRequiredModal,
      translations: [
      { language_code: 'en', translated_name: nameTypeEnModal },
      { language_code: 'sv', translated_name: nameTypeSvModal },
      ],
      ...(isDescriptionRequired(selectedTypeModal) && {
        options: optionsDropdownModal,
      }),
    };
    if (selectedItem?.id) {
      const updatedCustomField = listItems.map((customField: any) =>
        customField.id === selectedItem.id ? { ...customField, ...newCustomField } : customField
      );
      setListItems(updatedCustomField);
    } else  {
      setListItems([...listItems, newCustomField]);
    }
      setSelectedItem(null);
      setNameTypeEnModal('');
      setNameTypeSvModal('');
      setSelectedTypeModal(null);
      setIsInputModal(false);
      setIsOutputModal(false);
      setIsMultiModal(false);
      setIsRequiredModal(false);
      setOpenModalAddTaskType(false);
      setOptionsDropdownModal([]);
  }

  const handleCancel = () => {
    setSelectedItem(null);
    setNameTypeEnModal('');
    setNameTypeSvModal('');
    setSelectedTypeModal(null);
    setIsInputModal(false);
    setIsOutputModal(false);
    setIsMultiModal(false);
    setIsRequiredModal(false);
    setOpenModalAddTaskType(false);
    setAddOption(false);
    setOptionsDropdownModal([]);
  }


  const handleOptionModalClose = () => {
    setAddOption(false);
    setAddOptionEnModal('');
    setAddOptionSvModal('');
  }

  const handleEditClick = (item: any) => {
    setSelectedItem(item);
    setModeModal('edit');
    const matchingItems = listItems.filter((customField: any) => customField.id === item.id);

    setIsInputModal(matchingItems[0].is_input);
    setIsOutputModal(matchingItems[0].is_output);
    setIsMultiModal(matchingItems[0].is_multiselect);
    setIsRequiredModal(matchingItems[0].is_required);
    setNameTypeEnModal(matchingItems[0].translations.find((t: any) => t.language_code === 'en').translated_name);
    setNameTypeSvModal(matchingItems[0].translations.find((t: any) => t.language_code === 'sv').translated_name);
    setSelectedTypeModal(matchingItems[0].field_type);
    const findField = taskTypes.find((taskType: any) => taskType.id === matchingItems[0].field_type);

    if (findField) {
      const updatedField = { value: findField.id, label: findField.title };
      setSelectedTypeModal(updatedField as TaskType);
    }

    if (matchingItems[0].options) {   
      setOptionsDropdownModal(matchingItems[0].options)
    }
    setOpenModalAddTaskType(true);
  };

  const handleDeleteId = (item: any) => {
    const updatedCustomField = listItems.filter((customField: any) => customField.id !== item.id);
    setListItems(updatedCustomField);
  };

  // Dropdown options List

  const handleDeleteIdOptionModal = (id: any) => {
    const updatedDescriptionEn = optionsDropdownModal.filter((option: any) => option.id !== id);
    setOptionsDropdownModal(updatedDescriptionEn);
  }
  
  const addDataOptionsModal = () => {
    if (addOptionSvModal.trim() && addOptionEnModal.trim()) {
      const newOption = {
        id: generateUniqueId(),
        option_value: addOptionEnModal,
        translations: [
          { language_code: 'sv', translated_value: addOptionSvModal},
          { language_code: 'en', translated_value: addOptionEnModal },
        ],
      };
      setOptionsDropdownModal([...optionsDropdownModal, newOption]);
      setAddOptionSvModal('');
      setAddOptionEnModal('');
      setAddOption(false);
    }
  };

  const handleEditOptionModal = (id: any) => {
    setSelectedIdOptionModal(id);
    setModeOption('edit');
    const optionToEdit = optionsDropdownModal.find((option: any) => option.id === id);
    if (optionToEdit) {
      setAddOptionEnModal(optionToEdit.translations.find((t: any) => t.language_code === 'en')?.translated_value || '');
      setAddOptionSvModal(optionToEdit.translations.find((t: any) => t.language_code === 'sv')?.translated_value || '');
    }
    setAddOption(true);
  };

  const saveHandleOpenEditModal = () => {
    const updatedDescriptionEn = optionsDropdownModal.map((option: any) =>
      option.id === selectedIdOptionModal ? {
        ...option,
        option_value: addOptionEnModal,
        translations: [
        { language_code: 'sv', translated_value: addOptionSvModal },
        { language_code: 'en', translated_value: addOptionEnModal },
      ] } : option
    );
    setOptionsDropdownModal(updatedDescriptionEn);
    setSelectedIdOptionModal(null);
    setAddOptionEnModal('');
    setAddOptionSvModal('');
    setAddOption(false);
  }



  // Dropdown options
  const handleDeleteIdOption = (id: any) => {
    const updatedDescriptionEn = optionsDropdown.filter((option: any) => option.id !== id);
    setOptionsDropdown(updatedDescriptionEn);
  }
  const addDataOptions = () => {
    if (addOptionSv.trim() && addOptionEn.trim()) {
      const newOption = {
        id: generateUniqueId(),
        option_value: addOptionEn,
        translations: [
          { language_code: 'sv', translated_value: addOptionSv },
          { language_code: 'en', translated_value: addOptionEn },
        ],
      };
      setOptionsDropdown([...optionsDropdown, newOption]);
      setAddOptionSv('');
      setAddOptionEn('');
      setAddOption(false);
    }
  };

  const handleEditOption = (id: any) => {
    setSelectedIdOption(id);
    setModeOption('edit');
    const optionToEdit = optionsDropdown.find((option: any) => option.id === id);
    if (optionToEdit) {
      setAddOptionEn(optionToEdit.translations.find((t: any) => t.language_code === 'en')?.translated_value || '');
      setAddOptionSv(optionToEdit.translations.find((t: any) => t.language_code === 'sv')?.translated_value || '');
    }
    setAddOption(true);
  };

  const saveHandleOpenEdit = () => {
    const updatedDescriptionEn = optionsDropdown.map((option: any) =>
      option.id === selectedIdOption ? {
        ...option,
        option_value: addOptionEn,
        translations: [
        { language_code: 'sv', translated_value: addOptionSv },
        { language_code: 'en', translated_value: addOptionEn },
      ] } : option
    );
    setOptionsDropdown(updatedDescriptionEn);
    setSelectedIdOption(null);
    setAddOptionEn('');
    setAddOptionSv('');
    setAddOption(false);
  }

  return (
    <MainDiv>
      <h2 style={{paddingLeft: 15}}>
        {mode === 'create' ? formatMessage({ id: 'taskType.createCustomField', defaultMessage: 'Create Custom Field' })
          : formatMessage({ id: 'taskType.editCustomField', defaultMessage: 'Edit Custom Field' })}
      </h2>
    <DIV>
      <TaskTypeContainer>
        <Label style={{  textAlign: 'left' }}>{formatMessage({ id: 'taskType.fieldType', defaultMessage: 'Field Type' })}</Label>
          <Select
            value={selectedType}
            options={options}
            onChange={handleChange}
            placeholder={formatMessage({ id: 'taskType.selectType', defaultMessage: 'Select a task type...' })}
            isSearchable
            styles={customStyles}
          />
      </TaskTypeContainer>

      
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <Label style={{ marginBottom: '0.5rem', textAlign: 'left' }}>{formatMessage({ id: 'taskType.customFieldName', defaultMessage: 'Custom Field Name' })} SV</Label>
        <Input
          type="text"
          value={nameTypeSv}
          onChange={(e) => setNameTypeSv(e.target.value)}
          placeholder={formatMessage({ id: 'taskType.placeholderCustomFieldName', defaultMessage: 'Enter a task type name' })}
          color={userCompany?.button_color}  
        />
      </div>

      <div style={{display: 'flex', flexDirection: 'column'}}>
        <Label style={{ marginBottom: '0.5rem', textAlign: 'left' }}>{formatMessage({ id: 'taskType.customFieldName', defaultMessage: 'Custom Field Name' })} EN</Label>
        <Input
          type="text"
          value={nameTypeEn}
          onChange={(e) => setNameTypeEn(e.target.value)}
          placeholder={formatMessage({ id: 'taskType.placeholderCustomFieldName', defaultMessage: 'Enter a task type name' })}
          color={userCompany?.button_color}  
        />
      </div>

        {/* Dropdwon options main */}
        {selectedType && isDescriptionRequired(selectedType) && (
          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
          
            <div style={{ display: 'flex', flexDirection: 'column', }}>
            <Button appearance="secondary" type='button' style={{ height: 36, marginTop: 20, marginBottom: 20 }} onClick={() => {
              setAddOption(true)
              setModeOption('create')
            }
            }>
              <LuPlusCircle style={{ color: userCompany?.button_color }} size={20} />
              {formatMessage({ id: 'taskType.addOption', defaultMessage: 'Add option' })}
            </Button>
            

            {addOption && (<div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', position: 'fixed', transform: 'translate(85%, -10%)', backgroundColor: 'white', padding: '1.2rem', borderRadius: 8, border: '1px solid rgba(0, 0, 0, 0.3)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', zIndex: 1000 }}>
              <h3>
                {modeOption === 'create' ? 
                formatMessage({ id: 'taskType.createOption', defaultMessage: 'Create option' })
                  : 
                formatMessage({ id: 'taskType.editOption', defaultMessage: 'Edit option' })}
              </h3>
              <Label>{formatMessage({ id: 'taskType.options', defaultMessage: 'Options' })} SV</Label>
              <Input
                value={addOptionSv}
                onChange={(e) => setAddOptionSv(e.target.value)}
                name="optionsSv"
                placeholder={formatMessage({ id: 'taskType.placeholderOptions', defaultMessage: 'Enter an option' })}
                color={userCompany?.button_color}
              />

              <Label>{formatMessage({ id: 'taskType.options', defaultMessage: 'Options' })} EN</Label>
              <Input
                value={addOptionEn}
                onChange={(e) => setAddOptionEn(e.target.value)}
                name="optionsEn"
                placeholder={formatMessage({ id: 'taskType.placeholderOptions', defaultMessage: 'Enter an option' })}
                color={userCompany?.button_color}
              />
              <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignSelf: 'center' }}>
                {modeOption === 'create' ? <Button appearance='primary' type='button' onClick={() => addDataOptions()}>{formatMessage({ id: 'taskType.publish', defaultMessage: 'Publish' })}</Button> : <Button appearance='primary' type='button' onClick={() => saveHandleOpenEdit()}>{formatMessage({ id: 'taskType.save', defaultMessage: 'Save' })}</Button>}
              <Button appearance='secondary' type='button' onClick={() => setAddOption(false)}>{formatMessage({ id: 'taskType.cancel', defaultMessage: 'Cancel' })}</Button>
              </div>
            </div>
            )}
          </div>

            {optionsDropdown && (
              <div style={{marginBottom: 10}}>
                <h3 style={{ marginBottom: 10 }}>{formatMessage({ id: 'taskType.optionsList', defaultMessage: 'Options list' })}</h3>
            <Table>
              <Thead>
                <Tr>
                  <Th>{formatMessage({ id: 'taskType.options', defaultMessage: 'Options' })} SV</Th>
                  <Th>{formatMessage({ id: 'taskType.options', defaultMessage: 'Options' })} EN</Th>
                  <Th>{formatMessage({ id: 'taskType.actions', defaultMessage: 'Actions' })}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {optionsDropdown.map((option: any) => (
                  <Tr key={option.id}>
                    <Td style={{width: 80, wordBreak: 'break-word' }}>{option.translations.find((t: any) => t.language_code === 'sv')?.translated_value}</Td>
                    <Td style={{width: 80, wordBreak: 'break-word' }}>{option.translations.find((t: any) => t.language_code === 'en')?.translated_value}</Td>
                    <Td style={{width: 80 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                    <TooltipContainer title={formatMessage({ id: 'toolTip.edit', defaultMessage: 'Edit' })} >
                    <ButtonIcon type='button' onClick={() => { handleEditOption(option.id) }}>
                        <LuFileEdit size={14} />
                      </ButtonIcon>
                    </TooltipContainer>
                    <TooltipContainer title={formatMessage({ id: 'toolTip.delete', defaultMessage: 'Delete' })}>
                  <ButtonIcon type='button' onClick={() => {handleDeleteIdOption(option.id)}}>
                    <LuTrash size={14} />
                      </ButtonIcon>
                        </TooltipContainer>
                    </div>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
                </Table>
                </div>)}
          </div>    
        )}

        {/* Edior */}
        {selectedType && isEditorRequired(selectedType) &&(
          Editor
        )}

        {/* taskLsitbutton */}
        {selectedType && taskTypesList(selectedType) && (
          <Button type='button' appearance='secondary' style={{ height: 36, marginTop: 20, marginBottom: 20, width: '100%' }} onClick={() => setOpenList(true)}>
            {mode === 'create' ? <LuPlusCircle style={{ color: userCompany?.button_color }} size={20} /> : <LuFileEdit style={{ color: userCompany?.button_color }} size={15} /> }
            {mode === 'create' ?
              formatMessage({ id: 'taskType.createList', defaultMessage: 'Create list' })
              : formatMessage({ id: 'taskType.editList', defaultMessage: 'Edit list' })}
        
          </Button>
        )}
      <CheckBox
        isChecked={isInput}
        onChange={setIsInput}
        label={formatMessage({ id: 'taskType.isInput', defaultMessage: 'Is Input' })}
      />
      <CheckBox 
        isChecked={isOutput}
        onChange={setIsOutput}
        label={formatMessage({ id: 'taskType.isOutput', defaultMessage: 'Is Output' })}
      />
    {selectedType && isMultiselect(selectedType) && (
      <CheckBox 
        isChecked={isMulti}
        onChange={setIsMulti}
        label={formatMessage({ id: 'taskType.isMulti', defaultMessage: 'Is multiselect' })}
      />
    )}	
      <CheckBox 
        isChecked={isRequired}
        onChange={setIsRequired}
        label={formatMessage({ id: 'taskType.isRequired', defaultMessage: 'Is Required' })}
        />
      </DIV>


    {/* LIST */}
     {/* Create and edit pop up modal for taskList */}
     {openModalAddTaskType && taskTypesList(selectedType) && (
          <ModalOverlay style={{zIndex: 10002}}>
          <ModalContentWrapper style={{ borderRadius: 8, justifyContent: 'center', alignItems: 'center' }}> 
        <div style={{ backgroundColor: 'white', padding: '1.2rem', borderRadius: 20}}>
            <h3>
                {modeModal === 'create' ? 
                formatMessage({ id: 'taskType.createOption', defaultMessage: 'Create option' })
                  : 
                formatMessage({ id: 'taskType.editOption', defaultMessage: 'Edit option' })}
              </h3>
      <DIV style={{marginTop: 10}}>
              <TaskTypeContainer>
        <Label style={{  textAlign: 'left' }}>{formatMessage({ id: 'taskType.fieldType', defaultMessage: 'Field Type' })}</Label>
          <Select
            value={selectedTypeModal}
            options={optionsListArray}
            onChange={handleChangeModal}
            placeholder={formatMessage({ id: 'taskType.selectType', defaultMessage: 'Select a task type...' })}
            isSearchable
            styles={customStyles}
          />
      </TaskTypeContainer>

      
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <Label style={{ marginBottom: '0.5rem', textAlign: 'left' }}>{formatMessage({ id: 'taskType.customFieldName', defaultMessage: 'Custom Field Name' })} SV</Label>
        <Input
          type="text"
          value={nameTypeSvModal}
          onChange={(e) => setNameTypeSvModal(e.target.value)}
          placeholder={formatMessage({ id: 'taskType.placeholderCustomFieldName', defaultMessage: 'Enter a task type name' })}
          color={userCompany?.button_color}  
        />
      </div>

      <div style={{display: 'flex', flexDirection: 'column'}}>
        <Label style={{ marginBottom: '0.5rem', textAlign: 'left' }}>{formatMessage({ id: 'taskType.customFieldName', defaultMessage: 'Custom Field Name' })} EN</Label>
        <Input
          type="text"
          value={nameTypeEnModal}
          onChange={(e) => setNameTypeEnModal(e.target.value)}
          placeholder={formatMessage({ id: 'taskType.placeholderCustomFieldName', defaultMessage: 'Enter a task type name' })}
          color={userCompany?.button_color}  
        />
      </div>

      {/* Dropdwon options list modal */}
                {selectedTypeModal && isDescriptionRequired(selectedTypeModal) && (
        
          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
            <Button appearance="secondary" type='button' style={{ height: 40, marginTop: 20, marginBottom: 20 }} onClick={() => {
              setAddOption(true)
              setModeOption('create')
            }
            }>
              <LuPlusCircle style={{ color: userCompany?.button_color }} size={20} />
              {formatMessage({ id: 'taskType.addOption', defaultMessage: 'Add option' })}
            </Button>
            

                    {addOption && (
                    <div style={{
                      display: 'flex', flexDirection: 'column', textAlign: 'left',
              position: 'fixed', overflow: 'visible', backgroundColor: 'white', transform: 'translate(85%, -10%)',
              padding: '1.2rem', borderRadius: 8, border: '1px solid rgba(0, 0, 0, 0.3)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', zIndex: 1002,
                    }}>
              <h3>
                {modeOption === 'create' ? 
                formatMessage({ id: 'taskType.createOption', defaultMessage: 'Create option' })
                  : 
                formatMessage({ id: 'taskType.editOption', defaultMessage: 'Edit option' })}
              </h3>
              <Label>{formatMessage({ id: 'taskType.options', defaultMessage: 'Options' })} SV</Label>
              <Input
                value={addOptionSvModal}
                onChange={(e) => setAddOptionSvModal(e.target.value)}
                name="optionsSv"
                placeholder={formatMessage({ id: 'taskType.placeholderOptions', defaultMessage: 'Enter an option' })}
                color={userCompany?.button_color}
              />

              <Label>{formatMessage({ id: 'taskType.options', defaultMessage: 'Options' })} EN</Label>
              <Input
                value={addOptionEnModal}
                onChange={(e) => setAddOptionEnModal(e.target.value)}
                name="optionsEn"
                placeholder={formatMessage({ id: 'taskType.placeholderOptions', defaultMessage: 'Enter an option' })}
                color={userCompany?.button_color}
              />
              <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignSelf: 'center' }}>
                          {modeOption === 'create' ? <Button appearance='primary' type='button' onClick={() => addDataOptionsModal()}>{formatMessage({ id: 'taskType.publish', defaultMessage: 'Publish' })}</Button> :
                            <Button appearance='primary' type='button' onClick={() => saveHandleOpenEditModal()}>{formatMessage({ id: 'taskType.save', defaultMessage: 'Save' })}</Button>}
              <Button appearance='secondary' type='button' onClick={() => handleOptionModalClose()}>{formatMessage({ id: 'taskType.cancel', defaultMessage: 'Cancel' })}</Button>
                      </div>
                      </div>
            )}
            

            {optionsDropdownModal.length > 0 && (
              <div style={{marginBottom: 10}}>
                <h3 style={{ marginBottom: 10 }}>{formatMessage({ id: 'taskType.optionsList', defaultMessage: 'Options list' })}</h3>
            <Table>
              <Thead>
                <Tr>
                  <Th>{formatMessage({ id: 'taskType.options', defaultMessage: 'Options' })} SV</Th>
                  <Th>{formatMessage({ id: 'taskType.options', defaultMessage: 'Options' })} EN</Th>
                  <Th>{formatMessage({ id: 'taskType.actions', defaultMessage: 'Actions' })}</Th>
                </Tr>
              </Thead>            
              <Tbody>
                {optionsDropdownModal.map((option: any) => (
                  <Tr key={option.id}>
                    <Td style={{width: 80, wordBreak: 'break-word' }}>{option.translations.find((t: any) => t.language_code === 'sv')?.translated_value}</Td>
                    <Td style={{width: 80, wordBreak: 'break-word' }}>{option.translations.find((t: any) => t.language_code === 'en')?.translated_value}</Td>
                    <Td style={{width: 80 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                    <TooltipContainer title={formatMessage({ id: 'toolTip.edit', defaultMessage: 'Edit' })} >
                    <ButtonIcon type='button' onClick={() => { handleEditOptionModal(option.id) }}>
                        <LuFileEdit size={14} />
                      </ButtonIcon>
                    </TooltipContainer>
                    <TooltipContainer title={formatMessage({ id: 'toolTip.delete', defaultMessage: 'Delete' })}>
                  <ButtonIcon type='button' onClick={() => {handleDeleteIdOptionModal(option.id)}}>
                    <LuTrash size={14} />
                      </ButtonIcon>
                        </TooltipContainer>
                    </div>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
                </Table>
                </div>
              
              )}
          </div>    
        )}

      <CheckBox
        isChecked={isInputModal}
        onChange={setIsInputModal}
        label={formatMessage({ id: 'taskType.isInput', defaultMessage: 'Is Input' })}
      />
      <CheckBox 
        isChecked={isOutputModal}
        onChange={setIsOutputModal}
        label={formatMessage({ id: 'taskType.isOutput', defaultMessage: 'Is Output' })}
      />
    {selectedTypeModal && isMultiselect(selectedTypeModal) && (
      <CheckBox 
        isChecked={isMultiModal}
        onChange={setIsMultiModal}
        label={formatMessage({ id: 'taskType.isMulti', defaultMessage: 'Is multiselect' })}
      />
    )}	
      <CheckBox 
        isChecked={isRequiredModal}
        onChange={setIsRequiredModal}
        label={formatMessage({ id: 'taskType.isRequired', defaultMessage: 'Is Required' })}
                />
    </DIV>

    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 20, width: '100%', marginTop: 30 }}>
      <Button appearance='primary' onClick={handleConfirm}>{formatMessage({ id: 'taskType.publish', defaultMessage: 'Publish' })}</Button>
        <Button appearance='secondary' onClick={() => handleCancel()}>{formatMessage({ id: 'taskType.cancel', defaultMessage: 'Cancel' })}</Button>
              </div>
              </div>
            </ModalContentWrapper>
          </ModalOverlay>
        )}


      
        {/* The list will all items */}
        {openList && taskTypesList(selectedType) && (
          <ModalOverlay>
            <ModalContentWrapper style={{width: '60%', height: '90%', padding: 10}}>
              {listItems.length === 0 && (
                <ModalEmptyMessage>
                  <p>{formatMessage({ id: 'taskType.emptyListMessage', defaultMessage: 'Click on button to add a custom field to the list' })}</p>
                </ModalEmptyMessage>
            )}
              <Header style={{paddingRight: 10}}>
              <ButtonSecondary type="button" style={{ marginTop: 20, height: 40, width: '25%', alignSelf: 'flex-end', fontSize: 14 }} onClick={() => {
                setOpenModalAddTaskType(true)
                setModeModal('create')
              }}>
                 <LuPlusCircle size={14} />
                 <HideOnMobile>
                   {formatMessage({ id: 'taskType.addCustomField', defaultMessage: 'Add custom field' })}
                 </HideOnMobile>
              </ButtonSecondary>
            </Header>
            {listItems.length > 0 &&
              <DIV style={{marginTop: 20, maxHeight: '75%', paddingRight: 10}}>
              <Table>
              <Thead>
                <Tr>
                <Th>{formatMessage({ id: 'task.fieldName', defaultMessage: 'Field Name' })}</Th>
                <Th>{formatMessage({ id: 'taskType.typeField', defaultMessage: 'Type Field' })}</Th> 
                <Th>{formatMessage({ id: 'taskType.isInput', defaultMessage: 'Is Input' })}</Th>
                <Th>{formatMessage({ id: 'taskType.isOutput', defaultMessage: 'Is Output' })}</Th>
                <Th>{formatMessage({ id: 'taskType.isRequired', defaultMessage: 'Is Required' })}</Th>
                <Th>{formatMessage({ id: 'taskType.isMulti', defaultMessage: 'Is Multiselect' })}</Th>
                <Th>{formatMessage({ id: 'taskType.actions', defaultMessage: 'Actions' })}</Th>  
                </Tr>
              </Thead>
              <Tbody>
                  {listItems.map((item: any) => (
                  
                  <Tr key={item.id}>
                    <Td>{item.translations.find((t: any) => t.language_code === intl.locale)?.translated_name}</Td>
                    <Td>{item.field_type}</Td>
                    <Td>ㅤ{item.is_input ? <MdDone size={35} style={{color: 'green'}} /> : <MdClear size={35} style={{color: 'red'}}/>}</ Td>
                    <Td>ㅤ {item.is_output ? <MdDone size={35} style={{ color: 'green' }} /> : <MdClear size={35} style={{ color: 'red' }} />}</Td>
                    <Td>ㅤㅤ{item.is_required ? <MdDone size={35} style={{ color: 'green' }} /> : <MdClear size={35} style={{ color: 'red' }} />}</Td>
                    <Td>ㅤㅤ{isMultiselect({ value: item.field_type }) && item.is_multiselect ? <MdDone size={35} style={{color: 'green'}} /> : <MdClear size={35} style={{color: 'red'}}/>}</Td>
                    <Td>
                      <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                      <TooltipContainer title={formatMessage({ id: 'toolTip.edit', defaultMessage: 'Edit' })} >
                      <ButtonIcon type='button' onClick={() => handleEditClick(item)}>
                          <LuFileEdit size={14} />
                        </ButtonIcon>
                      </TooltipContainer>
                        <TooltipContainer title={formatMessage({ id: 'toolTip.delete', defaultMessage: 'Delete' })}>
                          <ButtonIcon type='button' onClick={() => handleDeleteId(item)}>
                            <LuTrash size={14} /> 
                          </ButtonIcon>
                        </TooltipContainer>
                      </div>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
                </Table>
                </DIV>}

                <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', bottom: 20, width: '100%' }}>
                <Button appearance='primary' type='button' onClick={() => setOpenList(false)} style={{ marginRight: 10 }}>{formatMessage({ id: 'taskType.publish', defaultMessage: 'Publish' })}</Button>
                <Button appearance='secondary' type='button' onClick={() => setOpenList(false)}>{formatMessage({ id: 'taskType.cancel', defaultMessage: 'Cancel' })}</Button>
                </div>
            </ModalContentWrapper>
            
          </ModalOverlay>
      )}     
    </MainDiv>
  );
}

export default ModalContent;