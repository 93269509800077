import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import { formatMessage } from '../../locale';
import { paths } from '../../config/paths';
import { LuPlus } from 'react-icons/lu';
import { TaskList } from './Components/TaskList';
import { Button, Paper } from '../../ui-kit';
import { SearchInputForm } from '../../ui-kit/Form/components/Input/SearchInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { HideOnMobile } from '../../components/BaseLayout/BaseLayout.styles';
import Hero from '../../ui-kit/Hero/Hero';
import { TaskPermisson } from '../../enum/Task/TaskPermisson';
import { useDownloadTaskQuery } from '../../slices/taskApiSlice';

const Tasks: React.FC = () => {
  const navigate = useNavigate();
  const { intl } = useIntlContext();
  const { userInfo, userCompany, permissions } = useSelector((state: RootState) => state.auth);
  
  const canDo = (permission: string) => { return permissions && !!permissions[permission]; };
  
  const [fetchTemplate, setFetchTemplate] = useState(false);
  const { data: taskTemplate, error, refetch: refetchTaskTemplate } = useDownloadTaskQuery({}, { skip: !fetchTemplate });

  useEffect(() => {
    if (fetchTemplate) {
      refetchTaskTemplate();
    }
  }, [fetchTemplate, refetchTaskTemplate]);

  useEffect(() => {
    if (error) {
      console.error('Failed to fetch task template:', error);
      setFetchTemplate(false);
    }
  }, [error]);

  useEffect(() => {
    if (taskTemplate) {
      const url = URL.createObjectURL(taskTemplate);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'task_template.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      setFetchTemplate(false);
    }
  }, [taskTemplate]);

  const handleFetchTemplate = () => {
    setFetchTemplate(true);
  };

  return (
    <Paper>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          appearance="secondary"
          onClick={() => handleFetchTemplate()}
        >
         {formatMessage({ id: 'task.getTask', defaultMessage: 'Get all tasks' })}
        </Button>
        <div style={{ paddingBottom: '20px' }}>
          {canDo(TaskPermisson.CREATE) && (
            <Button
              appearance="primary"
              onClick={() => navigate(paths.createTask)}
            >
              <LuPlus size={20} />
              <HideOnMobile>
                {formatMessage({ id: 'task.newTask', defaultMessage: 'New Task' })}
              </HideOnMobile>
            </Button>
          )}
        </div>
      </div>
      <Hero
        description={formatMessage({ id: 'hero.description', defaultMessage: 'Work environment management creates conditions for well-being, commitment and performance. A systematic work environment management involves a structured approach to managing and improving the work environment. At its core it includes continuous investigation, risk assessments, implementing preventive measures as well as identifying positive aspects and strengthening that. In short, a systematic work environment is about creating a sustainable, dynamic workplace where both the organization and its employees can thrive.' })}
        imagePath={'assets/hero2.jpg'}
        header={formatMessage({ id: 'hero.header', defaultMessage: 'Welcome to the ' })}
        subHeader={formatMessage({ id: 'hero.subHeaderTasks', defaultMessage: 'Here you will find Task.' })}
      />
      <TaskList />
    </Paper>
  );
};

export default Tasks;
