import styled from 'styled-components';

export const MainDiv = styled.div`
    background: white;
    border-radius: 20px;
    z-index: 9999;
    text-align: left;
`;

export const TaskTypeContainer = styled.div`
    margin-top: 20px;
    text-align: left;
    margin-bottom: 0.5rem;
`;
export const DIV = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 72vh;
  padding-left: 15px;
  padding-right: 35px;
  
  &::-webkit-scrollbar {
    margin: 0 10px 0 10px;  
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
export const Label = styled.label`
  margin-top: 10px;
  margin-bottom: 0.5rem;
  font-size: 14px;
  display: inline-block;
`

export const Input = styled.input<{ color?: string }>`
  width: 300px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 1rem;
  font-size: 14px;
  resize: none;

   &:hover {
    border-color: ${({ color }) => color};
  }
    &:focus {
    border-color: ${({ color }) => color};
    outline: none;
  }
`

export const TextArea = styled.textarea<{ color?: string }>`
  width: 300px;
  height: 50px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 1rem;
  resize: none;

  &:hover {
    border-color: ${({ color }) => color};
  }
  &:focus {
    border-color: ${({ color }) => color};
    outline: none;
  }
`

export const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  width: 300px;
`;

export const ModalOverlay = styled.div`
  position: absolute;
  z-index: 10001;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContentWrapper = styled.div`
  position: relative;
  background-color: white;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;

`;

export const ModalEmptyMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  p {
    font-size: 1.2rem;
    color: lightGrey;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: normal;
  }
`;

export const TaskTypeItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 180px;
  height: 150px;
  margin: 0.5rem;
  text-align: left;
  background-color: #f9f9f9;
`;


export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

export const Thead = styled.thead`
    background-color: #efefef;
    color: #000;
`

export const Tbody = styled.tbody`
    background-color: #fff;
`

export const Tr = styled.tr`
    &:nth-child(even) {
        background-color: #f9f9f9;
    }
`
export const Th = styled.th`
    padding: 10px;
    text-align: left;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    font-size: 12px;
`

export const Td = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  font-weight: normal;
`
export const ButtonIcon = styled.button`
  background: none;
  padding: 3px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
`;


