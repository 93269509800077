import styled from 'styled-components';

export const ChartWrapper = styled.div`
    flex: 1 1 33%;
    min-width: 300px;
    height: 400px;
    border-radius: 10px;
    padding: 40px 20px; /* Increase padding to add more space */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const ChartTitle = styled.h2`
    margin-bottom: 20px;
    font-size: 18px;
    color: #333;
`;
