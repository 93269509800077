import styled, { css } from 'styled-components'
import { Button } from '../../../'
import { shouldNotForwardPropsWithKeys } from '../../../utils/shouldNotForwardPropsWithKeys'

export const Container = styled.div`
  position: relative;
`

export const inputCSS = css<StyledInputProps>`
  height: 100%;
  width: 100%;
  border: 0;
  outline: none;
  transition: 180ms ease;
  padding: 0.5rem 1rem;
  

  ${({ border }) =>
    border === false &&
    css`
      padding: 0.5rem 1rem 0.5rem;
    `}

  ${({ theme }) => `
    ${theme.typography.bodyRegular}
    color: ${theme.palette.contrastHigh};
    background-color: ${theme.palette.transparent};
    border-radius: ${theme.shape.borderRadius};
    
    @media (max-width: ${theme.breakPoints.md}) {
      ${theme.typography.mediumRegular}
      border-radius: ${theme.shape.borderRadiusMedium};
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
      height: 2.5rem;
    }
  `}

  &::placeholder {
    color: ${({ theme }) => theme.palette.stroke};
  }

  ${({ iconCount }) =>
    iconCount &&
    css`
      padding-right: ${iconCount * 1.3 + 2}rem;
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      border-color: ${theme.palette.shape};
      color: ${theme.palette.contrastMedium};

      &:hover,
      &:focus,
      &:focus-within {
        border-color: ${({ theme }) => theme.palette.shape};
      }
    `}

  ${({ readOnly, disabled }) =>
    readOnly &&
    !disabled &&
    css`
      cursor: pointer;
    `}
`

export const Label = styled.label`
  margin-bottom: 0.5rem;
    color:black;
    font-weight: bold;
  display: inline-block;
  ${({ theme }) => `
    ${theme.typography.mediumRegular}`}
`

export const Actions = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
  height: 1.25rem;
  bottom: 0;
  margin: auto;
`

export const IconsContainer = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`

export interface StyledInputProps {
  error?: boolean | string
  value?: any
  disabled?: boolean
  readOnly?: boolean
  hasValue?: any
  iconCount?: number
  border?: boolean | undefined
}

const shouldNotForwardProps = shouldNotForwardPropsWithKeys<StyledInputProps>([
  'iconCount',
  'hasValue',
])

export const StyledInput = styled.input.withConfig({
  shouldForwardProp: (prop) => shouldNotForwardProps(prop),
})<StyledInputProps>`
  ${inputCSS}
`

export const SearchInputFormButton = styled(Button)`
  width: 3rem;
  height: calc(3.5rem - 16px);
  padding: 0;

  ${({ theme }) => ` 
    @media (max-width: ${theme.breakPoints.sm}) {
      width: 2.5rem;
      height: calc(3.25rem - 20px);
    }
  `}
  //@TODO: implement independent button for this input
  & svg {
    color: ${({ theme }) => 'white'} !important;
  }
`
