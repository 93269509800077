import React from 'react';
import { TableTitle } from './MultiSelect.styles'
import Select from 'react-select'
import { formatMessage } from '../../locale';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import { useSelector } from 'react-redux';
import { MenuPortal } from 'react-select/dist/declarations/src/components/Menu';
interface MultiSelectProps {
  label: any;
  name: string;
  options: { value: string | number; label: string }[];
  values: any[];
  onChange: (field: string, value: any) => void;
}

export const MultiSelect: React.FC<MultiSelectProps> = ({ label, name, options, values, onChange }) => {
  const { userCompany } = useSelector((state: any) => state.auth)
  const customStyles: any= {
    control: (provided: any, state: any) => ({
      ...provided,
      textAlign: 'left',
      fontWeight: 'normal',
      fontSize: 14,
      borderRadius: 8,
      borderColor: state.isFocused ? userCompany?.button_color : provided.borderColor,
      boxShadow: state.isFocused ? `0 0 0 1px ${userCompany?.button_color}` : provided.boxShadow,
      '&:hover': {
        borderColor: userCompany?.button_color,
      },
    }),
    option: (provided: any) => ({
      ...provided,
      textAlign: 'left',
      fontWeight: 'normal',
      fontSize: 12,
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9998 }),
    menu: (provided: any) => ({
      ...provided,
      overflowY: 'auto',
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: 105,
      "::-webkit-scrollbar": {
        width: "10px",
        height: "2px",
      },
      "::-webkit-scrollbar-track": {
        background: "lightGrey",
        borderRadius: "8px"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "8px"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
        borderRadius: "8px"
      }
    }),
  };

  return (
    <div style={{ position: 'relative', zIndex: 9997 }}>
      <TableTitle>{label}</TableTitle>
      <Select
        isMulti
        name={name}
        options={options}
        value={options?.filter(option => values?.includes(option.value))}
        onChange={(selectedOptions) => {
          const newValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
          onChange(name, newValues);
        }}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        placeholder={formatMessage({ id: 'handbookForm.placeholderMultiSelect', defaultMessage: 'Select...' })}
        styles={customStyles}
      />
    </div>
  );
};

/* @workspace render items hiraclur by child and parent id label is Department */