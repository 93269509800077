import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { clearToastMessage } from '../../slices/toastSlice';
import { messages } from '../../locale';
import { CgMoreVerticalO } from "react-icons/cg";
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import { formatMessage } from '../../locale'

// Define keyframes for animations
const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
`;

// Styled component for the toast container
const ToastContainer = styled.div<{ disappearing: boolean }>`
  position: fixed;
  bottom: 20px;
  left: 40%;
  transform: translateX(-50%);
  background: #f6a6a7;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 9999;
  opacity: 0;
  gap: 20px;
  animation: ${({ disappearing }) => (disappearing ? slideDown : slideUp)} 0.5s forwards;
`;

const ExpandButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  text-decoration: underline;
`;

const ToastTaskForm: React.FC<{ messageDetailed: any, message: any, expanded: any,  setExpanded: any }> = ({ messageDetailed, message, expanded, setExpanded }) => {
  const dispatch = useDispatch();
  const [disappearing, setDisappearing] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisappearing(true);
    }, expanded ? 4500 : 4500);

    const clearTimer = setTimeout(() => {
      dispatch(clearToastMessage());
    }, expanded ? 5000 : 5000); 

    return () => {
      clearTimeout(timer);
      clearTimeout(clearTimer);
    };
  }, [dispatch, expanded]);

  return (
    <ToastContainer disappearing={disappearing}>
      {!expanded && message +"."}
      {expanded && (
        <div>
          {messageDetailed.map((detail: any, index: number) => (
            <div key={index}>{detail}</div>
          ))}
        </div>
      )}
      <ExpandButton onClick={() => setExpanded(!expanded)}>
        {expanded ? formatMessage({ id: 'task.seeLess', defaultMessage: 'See less' })
          : <p style={{ marginLeft: 5 }}>{formatMessage({ id: 'task.seeMore', defaultMessage: 'See more' })}</p>}
      </ExpandButton>
    </ToastContainer>
  );
};

export default ToastTaskForm;
