import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAllTaskQuery, useGetAllTaskRegularQuery, useDeleteTaskMutation, useGetTaskStatusesQuery } from '../../../slices/taskApiSlice';
import { CardGrid } from '../../../ui-kit/Card/CarsTaskDashboard';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import PaginableList from '../../../ui-kit/PaginableListDashboard';
import { formatMessage } from '../../../locale'
import { useIntlContext } from '../../../locale/IntlProviderWrapper'
import { TaskPermisson } from '../../../enum/Task/TaskPermisson';
import { ButtonFilter, ButtonTabs } from '../Dashboard.styles';
import { IoFilterSharp } from "react-icons/io5";
import { IoIosClose } from "react-icons/io";
import CustomSelectMulti from '../../../ui-kit/Dropdown/DashboardMultiStatus/DropdownMulti';
import { TbSortAscending, TbSortDescending } from "react-icons/tb";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FaSortDown } from "react-icons/fa";

import styles from './DatePicker.module.css';
import style from './ClearButton.module.css';
interface TaskListProps {
  user: any;
}

const FilterButtons: React.FC<{
  user: any, personal: any, setPersonal: any, mapStatuses: any,
  setSelectedStatus: any, selectedStatus: any,
  sortByDate: any, setSortByDate: any
}> = ({ user, personal, setPersonal, selectedStatus, setSelectedStatus, mapStatuses, sortByDate, setSortByDate }) => {
  return (
    <div style={{ paddingRight: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px', gap: 25 }}>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', cursor: 'normal', border: '0.063rem solid #D2D4D7', borderRadius: '8px', boxShadow: '0 2px 3px rgba(0, 0, 0, 0.05)' }}>
      <ButtonTabs
        style={{ paddingLeft: 15, paddingRight: 15 }}
        color={user}
        onClick={() => setPersonal(false)}
        active={!personal}
      >
        {formatMessage({ id: 'task.all', defaultMessage: 'All' })}
      </ButtonTabs>
      <ButtonTabs
        style={{ paddingLeft: 15, paddingRight: 15 }}
        color={user}
        onClick={() => setPersonal('1')}
        active={personal}
      >
        {formatMessage({ id: 'task.personal', defaultMessage: 'PERSONAL' })}
        </ButtonTabs>
      </div>
      
      <CustomSelectMulti
        value={selectedStatus}
        onChange={(selectedOption: any) => setSelectedStatus(selectedOption)}
        options={mapStatuses}
        placeholder={formatMessage({ id: 'task.filterByStage', defaultMessage: 'Filter by stage...' })}
      />
    </div>
  );
};

export const TaskList: React.FC<TaskListProps> = ({ user }) => {
  const { intl } = useIntlContext();

  const { data: statuses } = useGetTaskStatusesQuery({});

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState<any[]>([]);
  const [statusPaginated, setStatusPaginated] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");

  const [overdue] = useState<any>(false);
  const [archived] = useState<any>(false);
  const [personal, setPersonal] = useState<any>(false);
  const [sortByDate, setSortByDate] = useState<any>(false);

  const mapStatuses = statuses?.data?.map((status: any) => ({ value: status.name, label: status.name }));

  useEffect(() => {
      setStatusPaginated(selectedStatus.map((status: any) => status.value));
  }, [selectedStatus]);

  return (
    <PaginableList query={useGetAllTaskQuery} my_page={true} currentPage={currentPage} overdue={overdue} archived={archived} personal={personal} statusPaginated={statusPaginated}     >
      {(task, isLoading, error, refetch) => {

        if (isLoading) return <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>;
        if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingTask', defaultMessage: 'Error while loading Task' })}</p></div>

        const cardsData = task.map((task: any) => ({
          id: task?.id,
          title: task?.title,
          assignee: task?.assignee,
          creator: task?.creator,
          taskType: task?.task_type,
          dueDate: task?.due_date,
          completedDate: task?.completed_date,
          font_color: task?.status?.font_color,
          background_color: task?.status?.bg_color,
          status: task?.status?.name,
          createdAt: task?.created_at,
          updatedAt: task?.updated_at,
          is_overdue: task?.is_overdue,
          is_archived: task?.is_archived,
        }));

        return <>
          <FilterButtons user={user} personal={personal} setPersonal={setPersonal} mapStatuses={mapStatuses} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} sortByDate={sortByDate} setSortByDate={setSortByDate} />
          <CardGrid cards={cardsData} />
        </>;
      }}
    </PaginableList>
  );
};

export default TaskList;