import styled from 'styled-components';

export const CourseCardWrapper = styled.div<{ isHovered: boolean }>`
    border: 1px solid #ddd;
    padding: 20px;
    width: 100%; /* Full width to fit container */
    max-width: 400px; /* Limits the maximum width to 500px */
    min-height: 474px; /* Fixed height of 500px */
    text-align: left; /* Align text to the left */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space between elements vertically */
    transition: transform 0.3s, box-shadow 0.3s;
    border: 0.063rem solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    ${({ isHovered }) =>
      isHovered &&
      `
        transform: scale(1.05);
    `}
`;

export const Title = styled.h2`
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
`;

export const DateText = styled.p`
    font-size: 14px;
    color: #666;
    margin: 5px 0;
`;

export const Button = styled.a`
    background-color: #a6336f;
    color: white;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin: 10px 0;
    display: inline-block; /* Align button to the left */
    width: fit-content; /* Ensure button is not full width */
`;

export const ProgressBar = styled.div`
    width: 100%;
    background-color: #e6e6e6;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px 0;
`;

export const Progress = styled.div<{ percent: number }>`
    width: ${({ percent }) => percent}%;
    height: 10px;
    background-color: #4caf50;
`;

export const LessonsText = styled.p`
    font-size: 14px;
    color: #333;
    margin: 5px 0;
`;

export const ProgressContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ImageContainer = styled.div`
    position: relative;
    background-color: #fff;
    height: 250px; /* Adjust height to fit better within the 500px card */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    border-radius: 12px;
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container */
    border-radius: 12px;
`;

export const CoursesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap onto the next row */
    gap: 20px; /* Space between items */
    justify-content: flex-start; /* Align items to the start of each row */
    width: 100%;
`;