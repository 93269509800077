import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntlContext } from '../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../locale';
import Button from '../../../ui-kit/Button/Button';
import { Actions, FormHeader, FormPaper, TabContent, FormContent, Column, Header, ButtonIcon, ListContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TdText,
  ColumnTopLevel,
} from './TaskTypeForm.style';
import { paths } from '../../../config/paths';
import Row from '../../../ui-kit/grid/Row';
import { FormikInput, FormikTextarea, FormikCheckbox } from '../../../ui-kit';
import { Form, Formik } from 'formik'
import { useSelector, useDispatch } from 'react-redux';
import { CustomFieldPermisson } from '../../../enum/TaskPermisson/CustomFieldPermisson';
import { LuPlusCircle } from "react-icons/lu";
import { HideOnMobile } from '../../../components/BaseLayout/BaseLayout.styles'
import ButtonSecondary from '../../../ui-kit/Button/ui/components/ButtonSecondary' 
import ConfirmationModal from '../../../ui-kit/Modal/ConfirmationModal';
import ModalConent from './ModalContent/ModalContent';
import ModalContentView from '../Components/ModalContent/ModalContentView/ModalContentView';
import { setCustomFieldCreated } from '../../../slices/customFieldSliceCreatedSlice';
import { useCreateTaskTypeMutation, useUpdateTaskTypeMutation, useGetOneTaskTypeQuery } from '../../../slices/taskTypeApiSlice';
import { LuFileEdit, LuTrash } from 'react-icons/lu';
import { MdDone, MdClear } from "react-icons/md";
import TooltipContainer from '../../../ui-kit/ToolTip/TooltipContainer';
import NoticeModal from '../../../ui-kit/Modal/NoticeModal';
import WangEditor from '../../../ui-kit/Form/components/Editor/WangEditorTaskType';
import { v4 as uuidv4 } from 'uuid';
import { useGetAllDepartmentsSimpleQuery } from '../../../slices/departmentsApiSlice';
import { useGetAllOfficesSimpleQuery } from '../../../slices/officeApiSlice';
import { useGetAllUsersQuery } from '../../../slices/usersApiSlices';
import { useGetAllJobTitlesSimpleQuery } from '../../../slices/jobTitleApiSlice';
import { useGetAllProjectGroupsSimpleQuery } from '../../../slices/projectGroupApiSlice';
import { useGetRolesCompanyNoIdQuery } from '../../../slices/companiesApiSlice';
import { transformToTree, flattenOptions } from '../../../slices/apiSlice';
import { MultiSelect } from '../../../ui-kit/MultiSelect/MultiSelect';


interface TaskTypeFormProps {
  mode: 'create' | 'edit';
}

interface TaskType {
  title?: string;
  value?: string;
}

interface DescriptionState {
  id?: string;
  translated_value: string;
}

export const TaskTypeForm = ({ mode }: TaskTypeFormProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { intl } = useIntlContext();
  const { taskTypeId } = useParams<{ taskTypeId: string }>();
  const { customFieldCreated } = useSelector((state: any) => state.customFieldSliceCreated);
  
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedType, setSelectedType] = useState<TaskType | null>(null);
  const [departmentTree, setDepartmentTree] = useState<any[]>([]);
  const [taskTypes] = useState([
    { id: CustomFieldPermisson.DROPDOWN, title: 'Dropdown' },
    { id: CustomFieldPermisson.USER, title: 'User' },
    { id: CustomFieldPermisson.DEPARTMENT, title: 'Department' },
    { id: CustomFieldPermisson.OFFICE, title: 'Office' },
    { id: CustomFieldPermisson.JOB_TITLE, title: 'Job Title' },
    { id: CustomFieldPermisson.PROJECT_GROUP, title: 'Project Group' },
    { id: CustomFieldPermisson.DATE, title: 'Date' },
    { id: CustomFieldPermisson.STRING, title: 'String' },
    { id: CustomFieldPermisson.NUMBER, title: 'Number' },
    { id: CustomFieldPermisson.EDITOR, title: 'Editor' },
    { id: CustomFieldPermisson.TaskTypesInArray, title: 'List' },

  ]);

  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [nameTypeSv, setNameTypeSv] = useState('');
  const [nameTypeEn, setNameTypeEn] = useState('');
  
  const [optionsDropdown, setOptionsDropdown] = useState<any>("");
  const [isInput, setIsInput] = useState(false);
  const [isOutput, setIsOutput] = useState(false);
  const[isMulti, setIsMulti] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [currentMode, setCurrentMode] = useState('');
  const [listItems, setListItems] = useState<any>([]);
 
  const { data: departmentsData } = useGetAllDepartmentsSimpleQuery({});
  const { data: officesData } = useGetAllOfficesSimpleQuery({});
  const { data: usersData } = useGetAllUsersQuery({});
  const { data: jobTitlesData } = useGetAllJobTitlesSimpleQuery({});
  const { data: projectGroupsData } = useGetAllProjectGroupsSimpleQuery({});
  const { data: rolesCompaniesData } = useGetRolesCompanyNoIdQuery({});

  const departments = departmentsData?.data || [];
  const offices = officesData?.data || [];
  const users = usersData?.data || [];
  const jobTitles = jobTitlesData?.data || [];
  const projectGroups = projectGroupsData?.data || [];
  const roles = rolesCompaniesData?.data || [];


  const [createTaskType] = useCreateTaskTypeMutation();
  const [updateTaskType] = useUpdateTaskTypeMutation();

  const { data: taskType, error, isLoading, refetch } = useGetOneTaskTypeQuery({ id: taskTypeId, update: true }, { skip: mode === 'create' });
  
  const customFieldRender: any = customFieldCreated;

  useEffect(() => {
    return () => {
      dispatch(setCustomFieldCreated([]));
      localStorage.removeItem('uuids');
      localStorage.removeItem('frontId');
    };
  }, []);

  useEffect(() => {
    if (mode === 'edit' && taskType?.data?.custom_fields?.some((field: any) => field.entity_files)) {
      const backUID = localStorage.getItem('uuids');
      const prevUUIDs = backUID ? JSON.parse(backUID) : [];
  
      const newUUIDs = taskType?.data?.custom_fields
        .filter((field: any) => field.entity_files)
        .flatMap((field: any) => field.entity_files.map((file: any) => file.uuid));
      const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
  
      localStorage.setItem('uuids', JSON.stringify(combinedUUIDs));
    }
  }, [taskType?.data?.custom_fields]);

  const initialValues = {
    title: taskType?.data.name || '',
    description: taskType?.data.description || '',
    content: taskType?.data?.custom_fields?.find((field: any) => field.field_type === CustomFieldPermisson.EDITOR)?.editor || '',
    allUsers: taskType?.data.is_company_wide_access || false,
    is_mandatory: taskType?.data.is_mandatory || false,
    departments: taskType?.data.accesses?.departments?.map((dept: any) => dept.id) || [],
    offices: taskType?.data.accesses?.offices?.map((office: any) => office.id) || [],
    job_titles: taskType?.data.accesses?.job_titles?.map((jobTitle: any) => jobTitle.id) || [],
    project_groups: taskType?.data.accesses?.project_groups?.map((projectGroup: any) => projectGroup.id) || [],
    users: taskType?.data.accesses?.users?.map((user: any) => user.id) || [],
    roles: taskType?.data?.accesses?.roles?.map((role: any) => role.id) || [], 
  };
  
  useEffect(() => {
    if (mode === 'edit' && taskTypeId) {
      refetch();
    }
  }, [mode, taskTypeId]);

  useEffect(() => {
    if (mode === 'edit' && taskType?.data?.custom_fields) {
      dispatch(setCustomFieldCreated(taskType.data.custom_fields));
    }
  }, [mode, taskType?.data?.custom_fields, dispatch]);

  useEffect(() => {
    if (departmentsData) {
      const tree = transformToTree(departmentsData.data);
      const flattenedOptions = flattenOptions(tree);
      setDepartmentTree(flattenedOptions);
    }
  }, [departmentsData]);

  const isEditor = (type: any) => {
    return [
      CustomFieldPermisson.EDITOR,
    ].includes(type?.value);
  };

  const isDescriptionRequired = (type: any) => {
    return [
      CustomFieldPermisson.DROPDOWN,
    ].includes(type?.value);
  };

  const isMultiselect = (type: any) => {
    return [
      CustomFieldPermisson.DROPDOWN,
      CustomFieldPermisson.DEPARTMENT,
      CustomFieldPermisson.PROJECT_GROUP,
      CustomFieldPermisson.JOB_TITLE,
      CustomFieldPermisson.OFFICE,
      CustomFieldPermisson.USER,
    ].includes(type?.value);
  };

  const taskTypesList = (type: any) => {
    return [
      CustomFieldPermisson.TaskTypesInArray,
    ].includes(type?.value);
  }

  
  const generateUniqueId = () => {
    let uniqueId: any;
    do {
      uniqueId = Math.random().toString(36).slice(2, 10);
    } while (customFieldRender.some((customField: any) => customField.id === uniqueId));
    return "tempID" + uniqueId;
  };

  const handleConfirm = (values: any) => {  
    const id = selectedItem?.id || generateUniqueId()
    const newCustomField = {
      id: id,
      field_type: selectedType?.value,
      is_input: isInput,
      is_output: isOutput,
      ...isMultiselect(selectedType) && {
        is_multiselect: isMulti,
      },
      is_required: isRequired,
      translations: [
      { language_code: 'en', translated_name: nameTypeEn },
      { language_code: 'sv', translated_name: nameTypeSv },
      ],
      ...isEditor(selectedType) && {
        editor: values.content,
      },
      ...(isDescriptionRequired(selectedType) && {
        options: optionsDropdown
      }), 
      ...taskTypesList(selectedType) && {
        list_items: listItems.map((item: any) => ({
          ...item,
          parent_id: id,
        })),
      },
    };
    if (selectedItem?.id) {
      const updatedCustomField = customFieldCreated.map((customField: any) =>
        customField.id === selectedItem.id ? { ...customField, ...newCustomField } : customField
      );
      dispatch(setCustomFieldCreated(updatedCustomField));
    } else  {
      dispatch(setCustomFieldCreated([...customFieldCreated, newCustomField]));
    }
      setConfirmationModal(false);
      setNameTypeEn('');
      setNameTypeSv('');
      setOptionsDropdown('');
      setIsInput(false);
      setIsOutput(false);
      setIsMulti(false);
      setIsRequired(false);
      setSelectedType(null);
      setSelectedItem(null);
      setListItems([]);
      setCurrentMode('');
  }

  function handleCancel() {
    setNameTypeEn('');
    setNameTypeSv('');
    setOptionsDropdown('');
    setCurrentMode('');
    setIsInput(false);
    setIsOutput(false);
    setIsMulti(false);
    setIsRequired(false);
    setSelectedType(null);
    setSelectedItem(null);
    setConfirmationModal(false);
    setListItems([]);
  }

  function handleCancelView() {
    setNameTypeEn('');
    setNameTypeSv('');
    setOptionsDropdown('');
    setIsInput(false);
    setIsOutput(false);
    setIsMulti(false);
    setIsRequired(false);
    setSelectedType(null);
    setSelectedItem(null);
    setOpenViewModal(false);
  }

  const viewModal = (item: any) => {
    setSelectedItem(item);
    const matchingItems = customFieldRender.filter((customField: any) => customField.id === item.id);
    setIsInput(matchingItems[0].is_input);
    setIsOutput(matchingItems[0].is_output);
    setIsMulti(matchingItems[0].is_multiselect);
    setIsRequired(matchingItems[0].is_required);
    setNameTypeEn(matchingItems[0].translations.find((t: any) => t.language_code === 'en').translated_name);
    setNameTypeSv(matchingItems[0].translations.find((t: any) => t.language_code === 'sv').translated_name);
    setSelectedType(matchingItems[0].field_type);
    const findField = taskTypes.find((taskType: any) => taskType.id === matchingItems[0].field_type);
    if (findField) {
      const updatedField = { value: findField.id, label: findField.title };
      setSelectedType(updatedField as TaskType);
    }
    if (matchingItems[0].options) {      
      setOptionsDropdown(matchingItems[0].options.map((item: any) => item.translations))
    }
    setCurrentMode('edit');
    setOpenViewModal(true);
  };

  const createCustomField = () => {
    setCurrentMode('create');
    setConfirmationModal(true);

  }

  const handleEditClick = (item: any) => {
    setSelectedItem(item);
    const matchingItems = customFieldRender.filter((customField: any) => customField.id === item.id);
    if(matchingItems[0].list_items) {
    const filteredListItems = matchingItems[0].list_items.filter((item: any) => item.parent_id === matchingItems[0].id);
      setListItems(filteredListItems);
    }
    setIsInput(matchingItems[0].is_input);
    setIsOutput(matchingItems[0].is_output);
    setIsMulti(matchingItems[0].is_multiselect);
    setIsRequired(matchingItems[0].is_required);
    setNameTypeEn(matchingItems[0].translations.find((t: any) => t.language_code === 'en').translated_name);
    setNameTypeSv(matchingItems[0].translations.find((t: any) => t.language_code === 'sv').translated_name);
    setSelectedType(matchingItems[0].field_type);
    const findField = taskTypes.find((taskType: any) => taskType.id === matchingItems[0].field_type);

    if (findField) {
      const updatedField = { value: findField.id, label: findField.title };
      setSelectedType(updatedField as TaskType);
    }

    if (matchingItems[0].options) {   
      setOptionsDropdown(matchingItems[0].options)
    }
    setConfirmationModal(true);
  };

  const handleDeleteId = (item: any) => {
    const updatedCustomField = customFieldRender.filter((customField: any) => customField.id !== item.id);
    dispatch(setCustomFieldCreated(updatedCustomField));
  };


  const handleSubmit = async (values: any) => {
    try {
      const backUID = localStorage.getItem('uuids');
      const frontUID = localStorage.getItem('frontId');

      let data: any = {
        name: values.title,
        description: values.description,
        custom_fields: customFieldCreated?.map((customField: any) => {
          let updatedCustomField = { ...customField};
          if (customField.options && customField?.options?.some((option: any) => option.option_value)) {
            const updatedOptions = customField?.options?.map((option: any) => ({
              ...option,
              value: option.option_value || option.value,
            }));
            updatedCustomField = {
              ...updatedCustomField,
              options: updatedOptions,
            };
          }
          if (typeof customField.id === 'string' && customField.id.startsWith('tempID'))  {
              delete updatedCustomField.id;
          }
          if (customField.field_type === CustomFieldPermisson.TaskTypesInArray && customField.list_items) {
            updatedCustomField.list_items = customField.list_items.map((item: any) => {
              // Remove temp IDs from top-level items
              if (item.id && typeof item.id === 'string' && item.id.startsWith('tempID')) {
                const { id, parent_id, ...rest } = item; // Exclude `id`
                item = rest; // Update the item to exclude `id`
              }
          
              // If item contains options, process them to remove temp IDs
              if (item?.field_type === CustomFieldPermisson.DROPDOWN && item.options) {
                const updatedOptions = item.options.map((option: any) => {
                  if (typeof option.id === 'string' && option.id.startsWith('tempID')) {
                    const { id, ...rest } = option; // Exclude `id`
                    return rest; // Return the new object without `id`
                  }
                  return option; // Return the original option if no changes
                });
                item = { ...item, options: updatedOptions }; // Update item with new options
              }
          
              return item; // Return the modified or original item
            });
          }
          
          if (customField.field_type === CustomFieldPermisson.DROPDOWN && customField.options) {
            updatedCustomField.options = customField.options.map((option: any) => {
              if (typeof option.id === 'string' && option.id.startsWith('tempID')) {
                const { id, ...rest } = option; // Destructure to exclude `id`
                return rest; // Return the new object without `id`
              }
              return option;
            });
          }         
          if (customField.field_type === CustomFieldPermisson.EDITOR && backUID) {
            try {
              const parsedBackUID = JSON.parse(backUID);
              if (Array.isArray(parsedBackUID) && parsedBackUID.length > 0) {
                updatedCustomField = {
                  ...updatedCustomField,
                  entity_files: {
                    uuid: frontUID || uuidv4(),
                    files: parsedBackUID.map((id: any) => ({
                      id,
                      tag: ""
                    })),
                  },
                };
                console.log('Attached entity_files to editor field:', updatedCustomField);
              }
            } catch (error) {
              console.error('Failed to parse backUID:', error);
            }
          }

  
          return updatedCustomField;
        }),
        is_company_wide_access: values.allUsers ? 1 : 0,
        access: {
          users: values.users,
          job_titles: values.job_titles,
          project_groups: values.project_groups,
          departments: values.departments,
          offices: values.offices,
          roles: values.roles,
        },
      };        
      
      data.status = 'published';
      if (mode === 'create') {
        await createTaskType({ data: data }).unwrap();
        navigate(paths.company);
        dispatch(setCustomFieldCreated([]));
      } else if (mode === 'edit' && taskTypeId) {
        console.log('taskTypeId', data);
        await updateTaskType({ id: taskTypeId, data: data }).unwrap();
        dispatch(setCustomFieldCreated([]));
        navigate(paths.company);
      }
    } catch (error) {
      console.error('Failed to create/update taskType:', error);
    }
  };

  return (
    <FormPaper>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values, setFieldValue }) => (
          <Form>
            <FormHeader>
              <div>
                <b>
                  {mode === 'edit'
                    ? formatMessage({ id: 'taskType.edit', defaultMessage: 'Edit: ' }) + initialValues.title
                    : formatMessage({ id: 'taskType.create', defaultMessage: 'Create Task Type' })}
                </b>
              </div>
              <Actions>
                <Button
                  appearance="secondary"
                  type="reset"
                  onClick={() => navigate(paths.company)}
                >
                  {formatMessage({ id: 'taskType.cancel', defaultMessage: 'Cancel' })}
                </Button>
                <Button appearance="primary" type="submit">
                  {mode === 'create'
                    ? formatMessage({ id: 'taskType.publish', defaultMessage: 'Publish' })
                    : formatMessage({ id: 'taskType.save', defaultMessage: 'Save' })}
                </Button>
              </Actions>
            </FormHeader>
            <TabContent>
              <FormContent>
                <ColumnTopLevel>
                  <Row>
                    <FormikInput
                      label={formatMessage({ id: 'taskType.name', defaultMessage: 'Name' })}
                      name="title"
                      placeholder={formatMessage({ id: 'taskType.placeholderTaskTypeTitle', defaultMessage: 'Enter a taskType title' })}
                      autoComplete="off"
                      required
                    />
                  </Row> 
                  <Row>
                  <FormikTextarea
                    label={formatMessage({ id: 'taskType.description', defaultMessage: 'Description' })}
                    name="description"
                    placeholder={formatMessage({ id: 'taskType.placeholderTaskTypeDescriptionr', defaultMessage: 'Enter a library description' })}
                    autoComplete="off"
                    required
                  />
                  </Row>
                </ColumnTopLevel>
              </FormContent>
            </TabContent>
            <Column>
            <FormikCheckbox
                    label={formatMessage({ id: 'handbookForm.checkBox', defaultMessage: 'All users' })}
                    name="allUsers"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const isChecked = e;
                      setFieldValue('allUsers', isChecked);
                      if (isChecked) {
                        setFieldValue('departments', []);
                        setFieldValue('offices', []);
                        setFieldValue('job_titles', []);
                        setFieldValue('project_groups', []);
                        setFieldValue('users', []);
                        setFieldValue('roles', []);
                      }
                    }}
                  />
                  {!values.allUsers && (     
                    <>
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.departmentLabel', defaultMessage: 'Department/Business Unit/Team' })}
                        name="departments"
                        options={departmentTree}
                        values={values.departments}
                        onChange={setFieldValue}
                      />
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.officeLabel', defaultMessage: 'Office' })}
                        name="offices"
                        options={offices.map((office: any) => ({ value: office.id, label: office.name }))}
                        values={values.offices}
                        onChange={setFieldValue}
                      />
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.jobTitleLabel', defaultMessage: 'Job Title' })}
                        name="job_titles"
                        options={jobTitles.map((jobTitle: any) => ({ value: jobTitle.id, label: jobTitle.name }))}
                        values={values.job_titles}
                        onChange={setFieldValue}
                      />
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.groupProjectLabel', defaultMessage: 'Project group' })}
                        name="project_groups"
                        options={projectGroups.map((projectGroup: any) => ({ value: projectGroup.id, label: projectGroup.name }))}
                        values={values.project_groups}
                        onChange={setFieldValue}
                      />
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.userLabel', defaultMessage: 'User' })}
                        name="users"
                        options={users.map((user: any) => ({ value: user.id, label: user.fullname }))}
                        values={values.users}
                        onChange={setFieldValue}
                      />
                     <MultiSelect
                        label={formatMessage({ id: 'library.userRoll', defaultMessage: 'User roles' })}
                        name="roles"
                        options={roles.map((role: any) => ({ value: role.id, label: `${role.title}` })) || []}
                        values={values.roles}
                        onChange={(field, value) => {
                        setFieldValue(field, value === "" || (Array.isArray(value) && value.length === 0) ? [] : value)}}
                      />
                    </>
                  )}
            </Column>
            <Header>
              <ButtonSecondary type="button" style={{ marginTop: 20, alignSelf: 'flex-end' }} onClick={() => { createCustomField() }}>
                 <LuPlusCircle size={20} />
                 <HideOnMobile>
                   {formatMessage({ id: 'taskType.addCustomField', defaultMessage: 'Add custom field' })}
                 </HideOnMobile>
              </ButtonSecondary>
            </Header>
         {customFieldRender.length > 0 && (   
            <ListContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>{formatMessage({ id: 'task.fieldName', defaultMessage: 'Field Name' })}</Th>
                    <Th>{formatMessage({ id: 'taskType.typeField', defaultMessage: 'Type Field' })}</Th> 
                    <Th>{formatMessage({ id: 'taskType.isInput', defaultMessage: 'Is Input' })}</Th>
                    <Th>{formatMessage({ id: 'taskType.isOutput', defaultMessage: 'Is Output' })}</Th>
                    <Th>{formatMessage({ id: 'taskType.isRequired', defaultMessage: 'Is Required' })}</Th>
                    <Th>{formatMessage({ id: 'taskType.isMulti', defaultMessage: 'Is Multiselect' })}</Th>
                    <Th>{formatMessage({ id: 'taskType.actions', defaultMessage: 'Actions' })}</Th>  
                  </Tr>
                </Thead>
            <Tbody>
              {customFieldRender.map((item: any, index: number) => (          
                <Tr key={item.id}>    
                  <TdText style={{ textTransform: 'capitalize' }} onClick={() => viewModal(item)}>{item.translations.find((t: any) => t.language_code === intl.locale).translated_name}</TdText>

                  <TdText style={{ textTransform: 'capitalize' }} onClick={() => viewModal(item)}>{item.field_type}</TdText>

                  <TdText onClick={() => viewModal(item)}>ㅤ{item.is_input ? <MdDone size={35} style={{color: 'green'}} /> : <MdClear size={35} style={{color: 'red'}}/>}</ TdText>

                  <TdText onClick={() => viewModal(item)}>ㅤ {item.is_output ? <MdDone size={35} style={{ color: 'green' }} /> : <MdClear size={35} style={{ color: 'red' }} />}</TdText>
                
                  <TdText onClick={() => viewModal(item)}>ㅤㅤ{item.is_required ? <MdDone size={35} style={{ color: 'green' }} /> : <MdClear size={35} style={{ color: 'red' }} />}</TdText>

                  <TdText onClick={() => viewModal(item)}>ㅤㅤ{isMultiselect({ value: item.field_type }) && item.is_multiselect ? <MdDone size={35} style={{color: 'green'}} /> : <MdClear size={35} style={{color: 'red'}}/>}</TdText>

                <Td>
                  <Actions>
                    <TooltipContainer title={formatMessage({ id: 'toolTip.edit', defaultMessage: 'Edit' })} >
                    <ButtonIcon type='button' onClick={() => { handleEditClick(item) }}>
                        <LuFileEdit size={18} />
                      </ButtonIcon>
                    </TooltipContainer>
                    <TooltipContainer title={formatMessage({ id: 'toolTip.delete', defaultMessage: 'Delete' })}>
                  <ButtonIcon type='button' onClick={() => {handleDeleteId(item)}}>
                    <LuTrash size={18} />
                      </ButtonIcon>
                    </TooltipContainer>
                  </Actions>
                </Td>  
              </Tr>            
              )
                  )}
                </Tbody>
              </Table>
            </ListContainer>
            )}
           {confirmationModal && (
            <ConfirmationModal
              header={<ModalConent mode={currentMode} taskTypes={taskTypes} selectedType={selectedType} setSelectedType={setSelectedType}
              nameTypeEn={nameTypeEn} setNameTypeEn={setNameTypeEn} nameTypeSv={nameTypeSv} setNameTypeSv={setNameTypeSv} optionsDropdown={optionsDropdown} setOptionsDropdown={setOptionsDropdown}
              isInput={isInput} setIsInput={setIsInput} isOutput={isOutput} setIsOutput={setIsOutput} isRequired={isRequired} setIsRequired={setIsRequired} isMulti={isMulti} setIsMulti={setIsMulti}
              listItems={listItems} setListItems={setListItems}  Editor={<WangEditor name='content'/>}
          />}
              onConfirm={()=> {handleConfirm(values)}}
              onCancel={handleCancel}
             />
      )}
      
      {openViewModal && (
        <NoticeModal
        header={<ModalContentView taskTypes={taskTypes} selectedType={selectedType} setSelectedType={setSelectedType}
        nameTypeEn={nameTypeEn} setNameTypeEn={setNameTypeEn} nameTypeSv={nameTypeSv} setNameTypeSv={setNameTypeSv} optionsDropdown={optionsDropdown}
        isInput={isInput} setIsInput={setIsInput} isOutput={isOutput} setIsOutput={setIsOutput} isRequired={isRequired} setIsRequired={setIsRequired} isMulti={isMulti} setIsMulti={setIsMulti}
        />}
        onConfirm={handleCancelView}
      />
      )}

          </Form>
        )} 
      
      </Formik>
    </FormPaper>
  );
};

export default TaskTypeForm;
