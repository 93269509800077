import styled from 'styled-components';

export const Button = styled.button<{ active: boolean, color: any }>`
  background-color: ${({ active, color }) => (active ? color : 'transparent')};
  color: ${({ active }) => (active ? 'white' : 'rgba(141, 141, 141, 1)')};
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  border-radius: 7px;
  font-weight: 400;

`;